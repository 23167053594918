.c-illustration {
	display: flex;
	justify-content: center;
	@include xs-max {
		padding-bottom: 8px;
	}

	&.--left {
		// @include lg {justify-content: start;}
		@include lg {img {padding-right: 16px;}}
	}

	&.--right {
		@include lg {img {padding-left: 16px;}}
	}

	&__section {
		@include sm-max {row-gap: 24px;}
		@include md {row-gap: 32px;}
		@include lg {row-gap: 40px;}
	}
}