/*------------------------------------------------------------------------------
Input
-- Consistently present form elements
-- Input, Select, and Textarea
-------------------------------------------------------------------------------- */


.c-input {
	display: flex;
	flex-direction: column;
	gap: 4px;
	position: relative; // Allowing for the absolute positioned icon
	
	// Input Icon Base Styles 
	&::after {
		font-family: "Font Awesome 6 Pro";
		font-weight: 400;
		color: $dark-text;
		position: absolute;
		right: 12px; 
		bottom: 13px;
		font-size: 1.4rem;
		pointer-events:none; // So that the icon is essentially clickable
	}

	// Common Input Reset / Styling
	input, select, textarea {
	    -webkit-appearance: none; // Removes all webkit styling
	    -moz-appearance:none; // Removes all moz styling
	    appearance: none;
	    position: relative;
	    vertical-align: top;
	    border: $border;
	    display: inline-block;
	    color: $medium-text;
	    outline: 0;
	    height: 40px;
	    width: 100%;
	    padding: 6px 12px;
	    border-radius: 2px;
	    font-size: 1.4rem;
	    line-height: 1.42857143;
	    background-color: #fff;
	    // box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
	    @include sm-max {
	        font-size: 1.6rem;
	    }

	    // Placeholder Text Color
	    &::placeholder {color: $light-text; opacity: 1;} // (Opacity if for firefox)

	    // Disabled Styles
	    &[disabled] {
	    	background-color: $light-bg;
	    	border: $border;
	    	opacity: 1;
        }
	}

	textarea {
		height: inherit!important;
	}

	// Horizontal Layout (Label next to Input)
	&.--horiz {
		flex-direction: row;
		align-items: center;
		gap: 6px;

		.c-input__label {
			margin-top: 1px;
		}
	}

	// Select
	select {
		padding: 6px 24px 6px 12px;
	}
	
	&.--select {

		// Arrow Icon
		&::after {
			content: "\f078"; /* fa-chevron-down */
		}

    	// Small Size
    	&.--small {
	    	input {
	    		height: 29px;
	    	}

    		select {
	    		padding: 3px 24px 3px 12px;
    		}
    	}
	}

    // Tag Icon (Left)
    &.--tag {

    	input {	padding: 6px 12px 6px 32px;	}

    	&::after {
			content: "\f02b"; /* fa-chevron-down */
			color: $blue;
			right: inherit;
			bottom: 11px;
			left: 12px;
			font-size: 1.6rem;
    	}
	}

    // Search
    &.--search {

    	input {	padding: 6px 12px 6px 38px;	}

    	&::after {
			content: "\f002"; /* fa-chevron-down */
			color: $black;
			right: inherit;
			bottom: 11px;
			left: 12px;
			font-size: 1.6rem;
    	}
	}

    // Input Button
	&.--has-btn {
		position: relative;

		.c-btn {
			position: absolute;
			bottom: 0;
			right: 0;
		}

	}

	// Label
	&__label {
		@extend .t-head;
	    font-size: 1.4em;
	}
}
