/*******************************************************************************************
* Mixins  – Global
********************************************************************************************/


/*------------------------------------------------------------------------------
Media Query Mixins
-------------------------------------------------------------------------------- */
@mixin below-360 {@media (max-width: 360px) {@content;}}
@mixin below-435 {@media (max-width: 435px) {@content;}}

@mixin xs-max { @media (max-width: 575px) { @content;}}

@mixin sm {@media (min-width: 576px) {@content;}}
@mixin sm-only {@media (min-width: 576px) and (max-width: 767px) {@content;}}
@mixin sm-max {@media (max-width: 767px) {@content;}}

@mixin sm-md {@media (min-width: 576px) and (max-width: 991px) {@content;}}

@mixin md {@media (min-width: 768px) {@content;}}
@mixin md-only {@media (min-width: 768px) and (max-width: 991px) {@content;}}
@mixin md-max {@media (max-width: 991px) {@content;}}
@mixin md-lg {@media (min-width: 768px) and (max-width: 1223px) {@content;}}

@mixin lg {@media (min-width: 992px) {@content;}}
@mixin lg-only {@media (min-width: 992px) and (max-width: 1223px) {@content;}}
@mixin lg-max {@media (max-width: 1223px) {@content;}}
@mixin lg-xl {@media (min-width: 992px) and (max-width: 1529px) {@content;}}

@mixin xl {@media (min-width: 1224px) {@content;}}
@mixin xl-only {@media (min-width: 1224px) and (max-width: 1529px) {@content;}}
@mixin xl-max {@media (max-width: 1529px) {@content;}}

@mixin xxl {@media (min-width: 1530px) {@content;}}

/*------------------------------------------------------------------------------
Style Mixins
-------------------------------------------------------------------------------- */
@mixin o-card {
    background-color: #fff;
    border: $border;
    border-radius: 4px;

    .--no-border {
        border: 0;
    }
}