.c-footer {
	min-height: $nav-height;
	background-color: $white;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: $border;

	&__copyright {
		@extend .t-body;
		@extend .t-body.--sm;
		color: $medium-text;
	}

	&__links {
		display: flex;
		gap: 24px;
		@extend .t-body;
		@extend .t-body.--sm;
		color: $medium-text;
		justify-content: center;
		@include sm {padding-left: 24px;}
	}

	&__wrap {
		text-align: center;
		display: flex;
		justify-content: space-between;
		align-items: center;
		row-gap: 8px;
		padding: 16px 0;

		p, ul {
			flex-grow: 1;
		}
	}
}