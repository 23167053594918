.c-csra_section {
	background-image: image-set(
		url('/img/backgrounds/bg-blue-blur.jpg') 1x,
		url('/img/backgrounds/bg-blue-blur@2x.jpg') 2x
	);
	background-size: cover;
	min-height: 633px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 30px;
	padding: 56px 16px;

	&__heading, &__subhead {
		color: $white;
		text-align: center;
		font-family: Barlow;
		text-transform: uppercase;
	}

	&__heading {
		text-shadow: 0px 1.956px 2.608px rgba(0, 0, 0, 0.47);
		font-size: 60px;
		font-weight: 700;
		letter-spacing: 5.5px;
		@include xs-max {
			font-size: 32px;
		}
	}

	&__subhead {
		text-shadow: 0px 2.608px 2.608px rgba(0, 0, 0, 0.40);
		font-size: 33px;
		font-weight: 275;
		letter-spacing: 8px;
		@include xs-max {
			font-size: 18px;
		}
	}

	&__body {
		@extend .t-body;
		@extend .t-body.--lh-lg;
		color: $white;
		max-width: 712px;
		text-align: center;
	}

}