.c-list {
	@extend .t-body;
	@extend .t-body.--lh-lg;
	list-style-type: disc;
	padding-left: 40px;
	display: flex;
	flex-direction: column;
	gap: 12px;

	&.--ordered {
		list-style-type: decimal;
	}

	&.--flush {
		padding-left: 16px;
	}

	&.--compact {
		gap: 6px;
	}

	li {
		display: list-item;
	}
}

