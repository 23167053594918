.c-link {
	color: $blue;
	display: inline-flex;
	gap: 4px;
	align-items: center ;
	cursor: pointer;

	&:hover {color: darken($blue, 15%);}

	&.--white {
		color: $white;
		&:hover {color: $white;}		
	}

	&.--light {
		color: $medium-text;
		&:hover {color: $dark-text;}		
	}
}