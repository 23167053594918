.c-icon-highlight {
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;

	&__icon {
		filter: drop-shadow(0px 28px 48px rgba(0, 0, 0, 0.16)) drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.30));
	}

	&__wrap {
		display: flex;
	}

	&.--server-bg {
		background-image: image-set(
			url('/img/backgrounds/bg-server.png') 1x,
			url('/img/backgrounds/bg-server@2x.png') 2x
		);
		background-size: cover;
		background-color: $blue-tint;
	}
}