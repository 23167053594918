.c-main-content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: 62px;
	padding-top: 32px;
	padding-bottom: 32px;
	
	@include sm {
		gap: 92px;
		padding-top: 60px;
		padding-bottom: 60px;
	}	

	@include lg {
		gap: 136px;
		padding-top: 80px;
		padding-bottom: 80px;
	}

	&.--no-gap {
		gap: 0;
	}
}