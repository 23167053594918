@charset "UTF-8";
/*******************************************************************************************
* Main Global Styles
********************************************************************************************/
/*******************************************************************************************
* Setup
********************************************************************************************/
/*!
 * Bootstrap Grid v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
/* NOTE: 
 * Minor modications have been made below
 * Search for "MOD" to see the changes.
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1224px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1164px; } }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.row-cols-4 > * {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -ms-flex-order: -1;
  order: -1; }

.order-last {
  -ms-flex-order: 13;
  order: 13; }

.order-0 {
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -ms-flex-order: 12;
  order: 12; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

/*# sourceMappingURL=bootstrap-grid.css.map */
/*! CSS Reset * https://meyerweb.com/eric/tools/css/reset/  *  v2.0 – 20110126  *  License: none (public domain) */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*******************************************************************************************
* Third Party Components
********************************************************************************************/
/*******************************************************************************************
* Variables, Mixins, Typography, and Utilities
********************************************************************************************/
/*******************************************************************************************
* Mixins  – Global
********************************************************************************************/
/*------------------------------------------------------------------------------
Media Query Mixins
-------------------------------------------------------------------------------- */
/*------------------------------------------------------------------------------
Style Mixins
-------------------------------------------------------------------------------- */
/*******************************************************************************************
* Base Styles
-- Global styles that set a "base" for the rest of the document
********************************************************************************************/
/*------------------------------------------------------------------------------
Global Adjustments
-------------------------------------------------------------------------------- */
html {
  font-size: 62.5%;
  /* This makes 1em = 10px */
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased; }

body, input, select, textarea {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  color: #444444;
  -webkit-text-size-adjust: none; }

body {
  padding-top: 57px;
  min-height: 100vh;
  display: flex;
  flex-direction: column; }

a {
  text-decoration: none;
  color: inherit; }

strong {
  font-weight: 700;
  font-style: normal;
  color: #000; }

em {
  font-style: italic; }

img, object, embed, video {
  max-width: 100%; }

button {
  border: 0; }

/*******************************************************************************************
* Typography
********************************************************************************************/
.t-head, .t-section__head, .c-input__label, .c-popup__head, .c-icon-group__head, .c-point__head, .c-footer__heading, .c-blog__section-head {
  font-weight: 700;
  line-height: 120%;
  font-size: 2em;
  color: #000;
  padding-bottom: .25em; }
  .t-head.--sm, .--sm.t-section__head, .--sm.c-input__label, .--sm.c-popup__head, .c-icon-group__head, .c-point__head, .--sm.c-footer__heading, .--sm.c-blog__section-head {
    font-size: 1.6em;
    padding-bottom: .2em; }
  .t-head.--md, .--md.t-section__head, .--md.c-input__label, .c-popup__head, .--md.c-icon-group__head, .--md.c-point__head, .c-footer__heading, .c-blog__section-head {
    font-size: 1.7em;
    padding-bottom: .2em; }
  .t-head.--lg, .--lg.t-section__head, .--lg.c-input__label, .--lg.c-popup__head, .--lg.c-icon-group__head, .--lg.c-point__head, .--lg.c-footer__heading, .--lg.c-blog__section-head {
    font-size: 2.4em; }
  .t-head.--lh-md, .t-section__head, .--lh-md.c-input__label, .--lh-md.c-popup__head, .--lh-md.c-icon-group__head, .c-point__head, .--lh-md.c-footer__heading, .--lh-md.c-blog__section-head {
    padding-bottom: .05em;
    line-height: 145%; }
  .t-head.--lh-lg, .--lh-lg.t-section__head, .--lh-lg.c-input__label, .--lh-lg.c-popup__head, .--lh-lg.c-icon-group__head, .--lh-lg.c-point__head, .--lh-lg.c-footer__heading, .--lh-lg.c-blog__section-head {
    line-height: 160%; }
  .t-head.--light, .--light.t-section__head, .--light.c-input__label, .--light.c-popup__head, .--light.c-icon-group__head, .--light.c-point__head, .c-footer__heading, .--light.c-blog__section-head {
    font-weight: 400; }
  .t-head.--with-icon i, .t-section__head i, .--with-icon.c-input__label i, .--with-icon.c-popup__head i, .--with-icon.c-icon-group__head i, .--with-icon.c-point__head i, .--with-icon.c-footer__heading i, .--with-icon.c-blog__section-head i, .t-head.--with-md-icon i, .--with-md-icon.t-section__head i, .--with-md-icon.c-input__label i, .--with-md-icon.c-popup__head i, .--with-md-icon.c-icon-group__head i, .--with-md-icon.c-point__head i, .--with-md-icon.c-footer__heading i, .--with-md-icon.c-blog__section-head i {
    padding-right: 8px;
    color: #0062FF;
    font-size: 2.4rem; }
    @media (max-width: 575px) {
      .t-head.--with-icon i, .t-section__head i, .--with-icon.c-input__label i, .--with-icon.c-popup__head i, .--with-icon.c-icon-group__head i, .--with-icon.c-point__head i, .--with-icon.c-footer__heading i, .--with-icon.c-blog__section-head i, .t-head.--with-md-icon i, .--with-md-icon.t-section__head i, .--with-md-icon.c-input__label i, .--with-md-icon.c-popup__head i, .--with-md-icon.c-icon-group__head i, .--with-md-icon.c-point__head i, .--with-md-icon.c-footer__heading i, .--with-md-icon.c-blog__section-head i {
        font-size: 2rem;
        top: 0 !important; } }
  .t-head.--with-md-icon i, .--with-md-icon.t-section__head i, .--with-md-icon.c-input__label i, .--with-md-icon.c-popup__head i, .--with-md-icon.c-icon-group__head i, .--with-md-icon.c-point__head i, .--with-md-icon.c-footer__heading i, .--with-md-icon.c-blog__section-head i {
    font-size: 2.1rem;
    padding-right: 4px; }

.t-body, .t-section__body, .c-popup-menu__item, .c-icon-group__body, .c-point__body, .c-csra_section__body, .c-list, .c-blog__paragraph {
  font-size: 1.6rem;
  line-height: 120%; }
  .t-body.--xs, .--xs.t-section__body, .--xs.c-popup-menu__item, .--xs.c-icon-group__body, .--xs.c-point__body, .--xs.c-csra_section__body, .--xs.c-list, .--xs.c-blog__paragraph {
    font-size: 1.2em; }
  .t-body.--sm, .--sm.t-section__body, .--sm.c-popup-menu__item, .c-icon-group__body, .c-point__body, .--sm.c-csra_section__body, .--sm.c-list, .--sm.c-blog__paragraph {
    font-size: 1.4em; }
  .t-body.--md, .--md.t-section__body, .t-section__body.c-popup__head, .t-section__body.c-footer__heading, .t-body.c-popup__head, .c-popup__head.c-popup-menu__item, .c-popup__head.c-icon-group__body, .c-popup__head.c-point__body, .c-popup__head.c-csra_section__body, .c-popup__head.c-list, .c-popup__head.c-blog__paragraph, .--md.c-popup-menu__item, .c-popup-menu__item.c-footer__heading, .--md.c-icon-group__body, .c-icon-group__body.c-footer__heading, .--md.c-point__body, .c-point__body.c-footer__heading, .--md.c-csra_section__body, .c-csra_section__body.c-footer__heading, .t-body.c-footer__heading, .c-footer__heading.c-list, .c-footer__heading.c-blog__paragraph, .--md.c-list, .--md.c-blog__paragraph {
    font-size: 1.8em; }
  .t-body.--lh-sm, .--lh-sm.t-section__body, .--lh-sm.c-popup-menu__item, .c-icon-group__body, .--lh-sm.c-point__body, .--lh-sm.c-csra_section__body, .--lh-sm.c-list, .--lh-sm.c-blog__paragraph {
    line-height: 150%; }
  .t-body.--lh-md, .--lh-md.t-section__body, .--lh-md.c-popup-menu__item, .--lh-md.c-icon-group__body, .c-point__body, .--lh-md.c-csra_section__body, .--lh-md.c-list, .c-blog__paragraph {
    line-height: 170%; }
  .t-body.--lh-lg, .t-section__body, .--lh-lg.c-popup-menu__item, .--lh-lg.c-icon-group__body, .--lh-lg.c-point__body, .c-csra_section__body, .c-list, .--lh-lg.c-blog__paragraph {
    line-height: 190%; }

/*------------------------------------------------------------------------------
Common Use
-------------------------------------------------------------------------------- */
/******************************************************************************************
* Utility Classes (-)
* -- Single purpose classes that can be applied to individual elements as needed
*******************************************************************************************/
/*------------------------------------------------------------------------------
Background Colors
-------------------------------------------------------------------------------- */
.-bgc-black {
  background-color: #000 !important; }

.-bgc-dark-text {
  background-color: #444444 !important; }

.-bgc-medium-text {
  background-color: #888888 !important; }

.-bgc-light-text {
  background-color: #9E9E9E !important; }

.-bgc-border-gray {
  background-color: #D7D7D7 !important; }

.-bgc-light-border-gray {
  background-color: #E7E7E7 !important; }

.-bgc-light-bg {
  background-color: #F9F9F9 !important; }

.-bgc-extra-light-bg {
  background-color: #FCFBFB !important; }

.-bgc-white {
  background-color: #FFF !important; }

.-bgc-blue {
  background-color: #0D63EE !important; }

.-bgc-green {
  background-color: #0D63EE !important; }

.-bgc-blue {
  background-color: #00D448 !important; }

.-bgc-purple {
  background-color: #9200B5 !important; }

.-bgc-red {
  background-color: #FF004A !important; }

.-bgc-yellow {
  background-color: #FFB800 !important; }

/*------------------------------------------------------------------------------
Text Colors
-------------------------------------------------------------------------------- */
.-c-black {
  color: #000 !important; }

.-c-dark-text {
  color: #444444 !important; }

.-c-medium-text {
  color: #888888 !important; }

.-c-light-text {
  color: #9E9E9E !important; }

.-c-border-gray {
  color: #D7D7D7 !important; }

.-c-light-border-gray {
  color: #E7E7E7 !important; }

.-c-light-bg {
  color: #F9F9F9 !important; }

.-c-extra-light-bg {
  color: #FCFBFB !important; }

.-c-white {
  color: #FFF !important; }

.-c-blue {
  color: #0062FF !important; }

.-c-blue-tint {
  color: #0D63EE !important; }

.-c-green {
  color: #00D448 !important; }

.-c-purple {
  color: #9200B5 !important; }

.-c-red {
  color: #FF004A !important; }

.-c-yellow {
  color: #FFB800 !important; }

.-c-linked-in {
  color: #2277B1 !important; }

.-c-facebook {
  color: #3D5A94 !important; }

.-c-instagram {
  color: #B74582 !important; }

.-c-youtube {
  color: #F23620 !important; }

/*------------------------------------------------------------------------------
Text Styles
-------------------------------------------------------------------------------- */
.-fw-bold {
  font-weight: 500; }

.-style-italic {
  font-style: italic; }

.-fz-10 {
  font-size: 12px; }

.-fz-12 {
  font-size: 12px; }

.-fz-14 {
  font-size: 14px; }

.-fz-18 {
  font-size: 18px; }

.-ta-center {
  text-align: center; }

/*------------------------------------------------------------------------------
Borders
-------------------------------------------------------------------------------- */
/*------------------------------------------------------------------------------
Nudge Elements
-------------------------------------------------------------------------------- */
[class*="-nudge-"] {
  position: relative !important; }

.-nudge-u-1 {
  top: -1px !important; }

.-nudge-u-2 {
  top: -2px !important; }

.-nudge-u-3 {
  top: -3px !important; }

.-nudge-u-4 {
  top: -4px !important; }

.-nudge-u-5 {
  top: -5px !important; }

.-nudge-u-6 {
  top: -6px !important; }

.-nudge-u-7 {
  top: -7px !important; }

.-nudge-u-8 {
  top: -8px !important; }

.-nudge-d-1 {
  top: 1px !important; }

.-nudge-d-2 {
  top: 2px !important; }

.-nudge-d-3 {
  top: 3px !important; }

.-nudge-d-4 {
  top: 4px !important; }

.-nudge-d-5 {
  top: 5px !important; }

.-nudge-d-6 {
  top: 6px !important; }

.-nudge-d-7 {
  top: 7px !important; }

.-nudge-d-8 {
  top: 8px !important; }

@media (max-width: 767px) {
  .-nudge-d-1-sm-max {
    top: 1px !important; }
  .-nudge-d-2-sm-max {
    top: 2px !important; }
  .-nudge-d-3-sm-max {
    top: 3px !important; }
  .-nudge-d-4-sm-max {
    top: 4px !important; }
  .-nudge-d-5-sm-max {
    top: 5px !important; }
  .-nudge-d-6-sm-max {
    top: 6px !important; }
  .-nudge-d-7-sm-max {
    top: 7px !important; }
  .-nudge-d-8-sm-max {
    top: 8px !important; } }

/*------------------------------------------------------------------------------
Margin Adjustments
-------------------------------------------------------------------------------- */
/* Top
--------------------------------------------- */
.-mt-neg-1 {
  margin-top: -1px !important; }

.-mt-0 {
  margin-top: 0px !important; }

.-mt-1 {
  margin-top: 1px !important; }

.-mt-2 {
  margin-top: 2px !important; }

.-mt-3 {
  margin-top: 3px !important; }

.-mt-4 {
  margin-top: 4px !important; }

.-mt-5 {
  margin-top: 5px !important; }

.-mt-6 {
  margin-top: 6px !important; }

.-mt-7 {
  margin-top: 7px !important; }

.-mt-8 {
  margin-top: 8px !important; }

.-mt-9 {
  margin-top: 9px !important; }

.-mt-10 {
  margin-top: 10px !important; }

.-mt-11 {
  margin-top: 11px !important; }

.-mt-12 {
  margin-top: 12px !important; }

.-mt-14 {
  margin-top: 14px !important; }

.-mt-16 {
  margin-top: 16px !important; }

.-mt-18 {
  margin-top: 18px !important; }

.-mt-20 {
  margin-top: 20px !important; }

.-mt-22 {
  margin-top: 22px !important; }

.-mt-24 {
  margin-top: 24px !important; }

.-mt-26 {
  margin-top: 26px !important; }

.-mt-28 {
  margin-top: 28px !important; }

.-mt-30 {
  margin-top: 30px !important; }

.-mt-32 {
  margin-top: 32px !important; }

.-mt-34 {
  margin-top: 34px !important; }

.-mt-36 {
  margin-top: 36px !important; }

.-mt-38 {
  margin-top: 38px !important; }

.-mt-40 {
  margin-top: 40px !important; }

.-mt-42 {
  margin-top: 42px !important; }

.-mt-44 {
  margin-top: 44px !important; }

.-mt-46 {
  margin-top: 46px !important; }

.-mt-48 {
  margin-top: 48px !important; }

.-mt-50 {
  margin-top: 50px !important; }

.-mt-52 {
  margin-top: 52px !important; }

.-mt-54 {
  margin-top: 54px !important; }

.-mt-56 {
  margin-top: 56px !important; }

.-mt-58 {
  margin-top: 58px !important; }

.-mt-60 {
  margin-top: 60px !important; }

.-mt-62 {
  margin-top: 62px !important; }

.-mt-64 {
  margin-top: 64px !important; }

.-mt-66 {
  margin-top: 66px !important; }

.-mt-68 {
  margin-top: 68px !important; }

.-mt-70 {
  margin-top: 70px !important; }

.-mt-72 {
  margin-top: 72px !important; }

.-mt-74 {
  margin-top: 74px !important; }

.-mt-76 {
  margin-top: 76px !important; }

.-mt-78 {
  margin-top: 78px !important; }

.-mt-80 {
  margin-top: 80px !important; }

@media (max-width: 575px) {
  .-mt-xs-0 {
    margin-top: 0px !important; }
  .-mt-xs-1 {
    margin-top: 1px !important; }
  .-mt-xs-2 {
    margin-top: 2px !important; }
  .-mt-xs-3 {
    margin-top: 3px !important; }
  .-mt-xs-4 {
    margin-top: 4px !important; }
  .-mt-xs-5 {
    margin-top: 5px !important; }
  .-mt-xs-6 {
    margin-top: 6px !important; }
  .-mt-xs-7 {
    margin-top: 7px !important; }
  .-mt-xs-8 {
    margin-top: 8px !important; }
  .-mt-xs-9 {
    margin-top: 9px !important; }
  .-mt-xs-10 {
    margin-top: 10px !important; }
  .-mt-xs-11 {
    margin-top: 11px !important; }
  .-mt-xs-12 {
    margin-top: 12px !important; }
  .-mt-xs-14 {
    margin-top: 14px !important; }
  .-mt-xs-16 {
    margin-top: 16px !important; }
  .-mt-xs-18 {
    margin-top: 18px !important; }
  .-mt-xs-20 {
    margin-top: 20px !important; }
  .-mt-xs-22 {
    margin-top: 22px !important; }
  .-mt-xs-24 {
    margin-top: 24px !important; }
  .-mt-xs-26 {
    margin-top: 26px !important; }
  .-mt-xs-28 {
    margin-top: 28px !important; }
  .-mt-xs-30 {
    margin-top: 30px !important; }
  .-mt-xs-32 {
    margin-top: 32px !important; }
  .-mt-xs-34 {
    margin-top: 34px !important; }
  .-mt-xs-36 {
    margin-top: 36px !important; }
  .-mt-xs-38 {
    margin-top: 38px !important; }
  .-mt-xs-40 {
    margin-top: 40px !important; }
  .-mt-xs-42 {
    margin-top: 42px !important; }
  .-mt-xs-44 {
    margin-top: 44px !important; }
  .-mt-xs-46 {
    margin-top: 46px !important; }
  .-mt-xs-48 {
    margin-top: 48px !important; }
  .-mt-xs-50 {
    margin-top: 50px !important; }
  .-mt-xs-52 {
    margin-top: 52px !important; }
  .-mt-xs-54 {
    margin-top: 54px !important; }
  .-mt-xs-56 {
    margin-top: 56px !important; }
  .-mt-xs-58 {
    margin-top: 58px !important; }
  .-mt-xs-60 {
    margin-top: 60px !important; }
  .-mt-xs-62 {
    margin-top: 62px !important; }
  .-mt-xs-64 {
    margin-top: 64px !important; }
  .-mt-xs-66 {
    margin-top: 66px !important; }
  .-mt-xs-68 {
    margin-top: 68px !important; }
  .-mt-xs-70 {
    margin-top: 70px !important; }
  .-mt-xs-72 {
    margin-top: 72px !important; }
  .-mt-xs-74 {
    margin-top: 74px !important; }
  .-mt-xs-76 {
    margin-top: 76px !important; }
  .-mt-xs-78 {
    margin-top: 78px !important; }
  .-mt-xs-80 {
    margin-top: 80px !important; } }

@media (min-width: 576px) {
  .-mt-sm-0 {
    margin-top: 0px !important; }
  .-mt-sm-1 {
    margin-top: 1px !important; }
  .-mt-sm-2 {
    margin-top: 2px !important; }
  .-mt-sm-3 {
    margin-top: 3px !important; }
  .-mt-sm-4 {
    margin-top: 4px !important; }
  .-mt-sm-5 {
    margin-top: 5px !important; }
  .-mt-sm-6 {
    margin-top: 6px !important; }
  .-mt-sm-7 {
    margin-top: 7px !important; }
  .-mt-sm-8 {
    margin-top: 8px !important; }
  .-mt-sm-9 {
    margin-top: 9px !important; }
  .-mt-sm-10 {
    margin-top: 10px !important; }
  .-mt-sm-11 {
    margin-top: 11px !important; }
  .-mt-sm-12 {
    margin-top: 12px !important; }
  .-mt-sm-14 {
    margin-top: 14px !important; }
  .-mt-sm-16 {
    margin-top: 16px !important; }
  .-mt-sm-18 {
    margin-top: 18px !important; }
  .-mt-sm-20 {
    margin-top: 20px !important; }
  .-mt-sm-22 {
    margin-top: 22px !important; }
  .-mt-sm-24 {
    margin-top: 24px !important; }
  .-mt-sm-26 {
    margin-top: 26px !important; }
  .-mt-sm-28 {
    margin-top: 28px !important; }
  .-mt-sm-30 {
    margin-top: 30px !important; }
  .-mt-sm-32 {
    margin-top: 32px !important; }
  .-mt-sm-34 {
    margin-top: 34px !important; }
  .-mt-sm-36 {
    margin-top: 36px !important; }
  .-mt-sm-38 {
    margin-top: 38px !important; }
  .-mt-sm-40 {
    margin-top: 40px !important; }
  .-mt-sm-42 {
    margin-top: 42px !important; }
  .-mt-sm-44 {
    margin-top: 44px !important; }
  .-mt-sm-46 {
    margin-top: 46px !important; }
  .-mt-sm-48 {
    margin-top: 48px !important; }
  .-mt-sm-50 {
    margin-top: 50px !important; }
  .-mt-sm-52 {
    margin-top: 52px !important; }
  .-mt-sm-54 {
    margin-top: 54px !important; }
  .-mt-sm-56 {
    margin-top: 56px !important; }
  .-mt-sm-58 {
    margin-top: 58px !important; }
  .-mt-sm-60 {
    margin-top: 60px !important; }
  .-mt-sm-62 {
    margin-top: 62px !important; }
  .-mt-sm-64 {
    margin-top: 64px !important; }
  .-mt-sm-66 {
    margin-top: 66px !important; }
  .-mt-sm-68 {
    margin-top: 68px !important; }
  .-mt-sm-70 {
    margin-top: 70px !important; }
  .-mt-sm-72 {
    margin-top: 72px !important; }
  .-mt-sm-74 {
    margin-top: 74px !important; }
  .-mt-sm-76 {
    margin-top: 76px !important; }
  .-mt-sm-78 {
    margin-top: 78px !important; }
  .-mt-sm-80 {
    margin-top: 80px !important; } }

@media (max-width: 767px) {
  .-mt-sm-max-0 {
    margin-top: 0px !important; }
  .-mt-sm-max-1 {
    margin-top: 1px !important; }
  .-mt-sm-max-2 {
    margin-top: 2px !important; }
  .-mt-sm-max-3 {
    margin-top: 3px !important; }
  .-mt-sm-max-4 {
    margin-top: 4px !important; }
  .-mt-sm-max-5 {
    margin-top: 5px !important; }
  .-mt-sm-max-6 {
    margin-top: 6px !important; }
  .-mt-sm-max-7 {
    margin-top: 7px !important; }
  .-mt-sm-max-8 {
    margin-top: 8px !important; }
  .-mt-sm-max-9 {
    margin-top: 9px !important; }
  .-mt-sm-max-10 {
    margin-top: 10px !important; }
  .-mt-sm-max-11 {
    margin-top: 11px !important; }
  .-mt-sm-max-12 {
    margin-top: 12px !important; }
  .-mt-sm-max-14 {
    margin-top: 14px !important; }
  .-mt-sm-max-16 {
    margin-top: 16px !important; }
  .-mt-sm-max-18 {
    margin-top: 18px !important; }
  .-mt-sm-max-20 {
    margin-top: 20px !important; }
  .-mt-sm-max-22 {
    margin-top: 22px !important; }
  .-mt-sm-max-24 {
    margin-top: 24px !important; }
  .-mt-sm-max-26 {
    margin-top: 26px !important; }
  .-mt-sm-max-28 {
    margin-top: 28px !important; }
  .-mt-sm-max-30 {
    margin-top: 30px !important; }
  .-mt-sm-max-32 {
    margin-top: 32px !important; }
  .-mt-sm-max-34 {
    margin-top: 34px !important; }
  .-mt-sm-max-36 {
    margin-top: 36px !important; }
  .-mt-sm-max-38 {
    margin-top: 38px !important; }
  .-mt-sm-max-40 {
    margin-top: 40px !important; }
  .-mt-sm-max-42 {
    margin-top: 42px !important; }
  .-mt-sm-max-44 {
    margin-top: 44px !important; }
  .-mt-sm-max-46 {
    margin-top: 46px !important; }
  .-mt-sm-max-48 {
    margin-top: 48px !important; }
  .-mt-sm-max-50 {
    margin-top: 50px !important; }
  .-mt-sm-max-52 {
    margin-top: 52px !important; }
  .-mt-sm-max-54 {
    margin-top: 54px !important; }
  .-mt-sm-max-56 {
    margin-top: 56px !important; }
  .-mt-sm-max-58 {
    margin-top: 58px !important; }
  .-mt-sm-max-60 {
    margin-top: 60px !important; }
  .-mt-sm-max-62 {
    margin-top: 62px !important; }
  .-mt-sm-max-64 {
    margin-top: 64px !important; }
  .-mt-sm-max-66 {
    margin-top: 66px !important; }
  .-mt-sm-max-68 {
    margin-top: 68px !important; }
  .-mt-sm-max-70 {
    margin-top: 70px !important; }
  .-mt-sm-max-72 {
    margin-top: 72px !important; }
  .-mt-sm-max-74 {
    margin-top: 74px !important; }
  .-mt-sm-max-76 {
    margin-top: 76px !important; }
  .-mt-sm-max-78 {
    margin-top: 78px !important; }
  .-mt-sm-max-80 {
    margin-top: 80px !important; } }

@media (min-width: 768px) {
  .-mt-md-0 {
    margin-top: 0px !important; }
  .-mt-md-1 {
    margin-top: 1px !important; }
  .-mt-md-2 {
    margin-top: 2px !important; }
  .-mt-md-3 {
    margin-top: 3px !important; }
  .-mt-md-4 {
    margin-top: 4px !important; }
  .-mt-md-5 {
    margin-top: 5px !important; }
  .-mt-md-6 {
    margin-top: 6px !important; }
  .-mt-md-7 {
    margin-top: 7px !important; }
  .-mt-md-8 {
    margin-top: 8px !important; }
  .-mt-md-9 {
    margin-top: 9px !important; }
  .-mt-md-10 {
    margin-top: 10px !important; }
  .-mt-md-11 {
    margin-top: 11px !important; }
  .-mt-md-12 {
    margin-top: 12px !important; }
  .-mt-md-14 {
    margin-top: 14px !important; }
  .-mt-md-16 {
    margin-top: 16px !important; }
  .-mt-md-18 {
    margin-top: 18px !important; }
  .-mt-md-20 {
    margin-top: 20px !important; }
  .-mt-md-22 {
    margin-top: 22px !important; }
  .-mt-md-24 {
    margin-top: 24px !important; }
  .-mt-md-26 {
    margin-top: 26px !important; }
  .-mt-md-28 {
    margin-top: 28px !important; }
  .-mt-md-30 {
    margin-top: 30px !important; }
  .-mt-md-32 {
    margin-top: 32px !important; }
  .-mt-md-34 {
    margin-top: 34px !important; }
  .-mt-md-36 {
    margin-top: 36px !important; }
  .-mt-md-38 {
    margin-top: 38px !important; }
  .-mt-md-40 {
    margin-top: 40px !important; }
  .-mt-md-42 {
    margin-top: 42px !important; }
  .-mt-md-44 {
    margin-top: 44px !important; }
  .-mt-md-46 {
    margin-top: 46px !important; }
  .-mt-md-48 {
    margin-top: 48px !important; }
  .-mt-md-50 {
    margin-top: 50px !important; }
  .-mt-md-52 {
    margin-top: 52px !important; }
  .-mt-md-54 {
    margin-top: 54px !important; }
  .-mt-md-56 {
    margin-top: 56px !important; }
  .-mt-md-58 {
    margin-top: 58px !important; }
  .-mt-md-60 {
    margin-top: 60px !important; }
  .-mt-md-62 {
    margin-top: 62px !important; }
  .-mt-md-64 {
    margin-top: 64px !important; }
  .-mt-md-66 {
    margin-top: 66px !important; }
  .-mt-md-68 {
    margin-top: 68px !important; }
  .-mt-md-70 {
    margin-top: 70px !important; }
  .-mt-md-72 {
    margin-top: 72px !important; }
  .-mt-md-74 {
    margin-top: 74px !important; }
  .-mt-md-76 {
    margin-top: 76px !important; }
  .-mt-md-78 {
    margin-top: 78px !important; }
  .-mt-md-80 {
    margin-top: 80px !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .-mt-md-only-0 {
    margin-top: 0px !important; }
  .-mt-md-only-1 {
    margin-top: 1px !important; }
  .-mt-md-only-2 {
    margin-top: 2px !important; }
  .-mt-md-only-3 {
    margin-top: 3px !important; }
  .-mt-md-only-4 {
    margin-top: 4px !important; }
  .-mt-md-only-5 {
    margin-top: 5px !important; }
  .-mt-md-only-6 {
    margin-top: 6px !important; }
  .-mt-md-only-7 {
    margin-top: 7px !important; }
  .-mt-md-only-8 {
    margin-top: 8px !important; }
  .-mt-md-only-9 {
    margin-top: 9px !important; }
  .-mt-md-only-10 {
    margin-top: 10px !important; }
  .-mt-md-only-11 {
    margin-top: 11px !important; }
  .-mt-md-only-12 {
    margin-top: 12px !important; }
  .-mt-md-only-14 {
    margin-top: 14px !important; }
  .-mt-md-only-16 {
    margin-top: 16px !important; }
  .-mt-md-only-18 {
    margin-top: 18px !important; }
  .-mt-md-only-20 {
    margin-top: 20px !important; }
  .-mt-md-only-22 {
    margin-top: 22px !important; }
  .-mt-md-only-24 {
    margin-top: 24px !important; }
  .-mt-md-only-26 {
    margin-top: 26px !important; }
  .-mt-md-only-28 {
    margin-top: 28px !important; }
  .-mt-md-only-30 {
    margin-top: 30px !important; }
  .-mt-md-only-32 {
    margin-top: 32px !important; }
  .-mt-md-only-34 {
    margin-top: 34px !important; }
  .-mt-md-only-36 {
    margin-top: 36px !important; }
  .-mt-md-only-38 {
    margin-top: 38px !important; }
  .-mt-md-only-40 {
    margin-top: 40px !important; }
  .-mt-md-only-42 {
    margin-top: 42px !important; }
  .-mt-md-only-44 {
    margin-top: 44px !important; }
  .-mt-md-only-46 {
    margin-top: 46px !important; }
  .-mt-md-only-48 {
    margin-top: 48px !important; }
  .-mt-md-only-50 {
    margin-top: 50px !important; }
  .-mt-md-only-52 {
    margin-top: 52px !important; }
  .-mt-md-only-54 {
    margin-top: 54px !important; }
  .-mt-md-only-56 {
    margin-top: 56px !important; }
  .-mt-md-only-58 {
    margin-top: 58px !important; }
  .-mt-md-only-60 {
    margin-top: 60px !important; }
  .-mt-md-only-62 {
    margin-top: 62px !important; }
  .-mt-md-only-64 {
    margin-top: 64px !important; }
  .-mt-md-only-66 {
    margin-top: 66px !important; }
  .-mt-md-only-68 {
    margin-top: 68px !important; }
  .-mt-md-only-70 {
    margin-top: 70px !important; }
  .-mt-md-only-72 {
    margin-top: 72px !important; }
  .-mt-md-only-74 {
    margin-top: 74px !important; }
  .-mt-md-only-76 {
    margin-top: 76px !important; }
  .-mt-md-only-78 {
    margin-top: 78px !important; }
  .-mt-md-only-80 {
    margin-top: 80px !important; } }

@media (max-width: 991px) {
  .-mt-md-max-0 {
    margin-top: 0px !important; }
  .-mt-md-max-1 {
    margin-top: 1px !important; }
  .-mt-md-max-2 {
    margin-top: 2px !important; }
  .-mt-md-max-3 {
    margin-top: 3px !important; }
  .-mt-md-max-4 {
    margin-top: 4px !important; }
  .-mt-md-max-5 {
    margin-top: 5px !important; }
  .-mt-md-max-6 {
    margin-top: 6px !important; }
  .-mt-md-max-7 {
    margin-top: 7px !important; }
  .-mt-md-max-8 {
    margin-top: 8px !important; }
  .-mt-md-max-9 {
    margin-top: 9px !important; }
  .-mt-md-max-10 {
    margin-top: 10px !important; }
  .-mt-md-max-11 {
    margin-top: 11px !important; }
  .-mt-md-max-12 {
    margin-top: 12px !important; }
  .-mt-md-max-14 {
    margin-top: 14px !important; }
  .-mt-md-max-16 {
    margin-top: 16px !important; }
  .-mt-md-max-18 {
    margin-top: 18px !important; }
  .-mt-md-max-20 {
    margin-top: 20px !important; }
  .-mt-md-max-22 {
    margin-top: 22px !important; }
  .-mt-md-max-24 {
    margin-top: 24px !important; }
  .-mt-md-max-26 {
    margin-top: 26px !important; }
  .-mt-md-max-28 {
    margin-top: 28px !important; }
  .-mt-md-max-30 {
    margin-top: 30px !important; }
  .-mt-md-max-32 {
    margin-top: 32px !important; }
  .-mt-md-max-34 {
    margin-top: 34px !important; }
  .-mt-md-max-36 {
    margin-top: 36px !important; }
  .-mt-md-max-38 {
    margin-top: 38px !important; }
  .-mt-md-max-40 {
    margin-top: 40px !important; }
  .-mt-md-max-42 {
    margin-top: 42px !important; }
  .-mt-md-max-44 {
    margin-top: 44px !important; }
  .-mt-md-max-46 {
    margin-top: 46px !important; }
  .-mt-md-max-48 {
    margin-top: 48px !important; }
  .-mt-md-max-50 {
    margin-top: 50px !important; }
  .-mt-md-max-52 {
    margin-top: 52px !important; }
  .-mt-md-max-54 {
    margin-top: 54px !important; }
  .-mt-md-max-56 {
    margin-top: 56px !important; }
  .-mt-md-max-58 {
    margin-top: 58px !important; }
  .-mt-md-max-60 {
    margin-top: 60px !important; }
  .-mt-md-max-62 {
    margin-top: 62px !important; }
  .-mt-md-max-64 {
    margin-top: 64px !important; }
  .-mt-md-max-66 {
    margin-top: 66px !important; }
  .-mt-md-max-68 {
    margin-top: 68px !important; }
  .-mt-md-max-70 {
    margin-top: 70px !important; }
  .-mt-md-max-72 {
    margin-top: 72px !important; }
  .-mt-md-max-74 {
    margin-top: 74px !important; }
  .-mt-md-max-76 {
    margin-top: 76px !important; }
  .-mt-md-max-78 {
    margin-top: 78px !important; }
  .-mt-md-max-80 {
    margin-top: 80px !important; } }

@media (min-width: 992px) {
  .-mt-lg-0 {
    margin-top: 0px !important; }
  .-mt-lg-1 {
    margin-top: 1px !important; }
  .-mt-lg-2 {
    margin-top: 2px !important; }
  .-mt-lg-3 {
    margin-top: 3px !important; }
  .-mt-lg-4 {
    margin-top: 4px !important; }
  .-mt-lg-5 {
    margin-top: 5px !important; }
  .-mt-lg-6 {
    margin-top: 6px !important; }
  .-mt-lg-7 {
    margin-top: 7px !important; }
  .-mt-lg-8 {
    margin-top: 8px !important; }
  .-mt-lg-9 {
    margin-top: 9px !important; }
  .-mt-lg-10 {
    margin-top: 10px !important; }
  .-mt-lg-11 {
    margin-top: 11px !important; }
  .-mt-lg-12 {
    margin-top: 12px !important; }
  .-mt-lg-14 {
    margin-top: 14px !important; }
  .-mt-lg-16 {
    margin-top: 16px !important; }
  .-mt-lg-18 {
    margin-top: 18px !important; }
  .-mt-lg-20 {
    margin-top: 20px !important; }
  .-mt-lg-22 {
    margin-top: 22px !important; }
  .-mt-lg-24 {
    margin-top: 24px !important; }
  .-mt-lg-26 {
    margin-top: 26px !important; }
  .-mt-lg-28 {
    margin-top: 28px !important; }
  .-mt-lg-30 {
    margin-top: 30px !important; }
  .-mt-lg-32 {
    margin-top: 32px !important; }
  .-mt-lg-34 {
    margin-top: 34px !important; }
  .-mt-lg-36 {
    margin-top: 36px !important; }
  .-mt-lg-38 {
    margin-top: 38px !important; }
  .-mt-lg-40 {
    margin-top: 40px !important; }
  .-mt-lg-42 {
    margin-top: 42px !important; }
  .-mt-lg-44 {
    margin-top: 44px !important; }
  .-mt-lg-46 {
    margin-top: 46px !important; }
  .-mt-lg-48 {
    margin-top: 48px !important; }
  .-mt-lg-50 {
    margin-top: 50px !important; }
  .-mt-lg-52 {
    margin-top: 52px !important; }
  .-mt-lg-54 {
    margin-top: 54px !important; }
  .-mt-lg-56 {
    margin-top: 56px !important; }
  .-mt-lg-58 {
    margin-top: 58px !important; }
  .-mt-lg-60 {
    margin-top: 60px !important; }
  .-mt-lg-62 {
    margin-top: 62px !important; }
  .-mt-lg-64 {
    margin-top: 64px !important; }
  .-mt-lg-66 {
    margin-top: 66px !important; }
  .-mt-lg-68 {
    margin-top: 68px !important; }
  .-mt-lg-70 {
    margin-top: 70px !important; }
  .-mt-lg-72 {
    margin-top: 72px !important; }
  .-mt-lg-74 {
    margin-top: 74px !important; }
  .-mt-lg-76 {
    margin-top: 76px !important; }
  .-mt-lg-78 {
    margin-top: 78px !important; }
  .-mt-lg-80 {
    margin-top: 80px !important; } }

@media (min-width: 992px) and (max-width: 1223px) {
  .-mt-lg-only-0 {
    margin-top: 0px !important; }
  .-mt-lg-only-1 {
    margin-top: 1px !important; }
  .-mt-lg-only-2 {
    margin-top: 2px !important; }
  .-mt-lg-only-3 {
    margin-top: 3px !important; }
  .-mt-lg-only-4 {
    margin-top: 4px !important; }
  .-mt-lg-only-5 {
    margin-top: 5px !important; }
  .-mt-lg-only-6 {
    margin-top: 6px !important; }
  .-mt-lg-only-7 {
    margin-top: 7px !important; }
  .-mt-lg-only-8 {
    margin-top: 8px !important; }
  .-mt-lg-only-9 {
    margin-top: 9px !important; }
  .-mt-lg-only-10 {
    margin-top: 10px !important; }
  .-mt-lg-only-11 {
    margin-top: 11px !important; }
  .-mt-lg-only-12 {
    margin-top: 12px !important; }
  .-mt-lg-only-14 {
    margin-top: 14px !important; }
  .-mt-lg-only-16 {
    margin-top: 16px !important; }
  .-mt-lg-only-18 {
    margin-top: 18px !important; }
  .-mt-lg-only-20 {
    margin-top: 20px !important; }
  .-mt-lg-only-22 {
    margin-top: 22px !important; }
  .-mt-lg-only-24 {
    margin-top: 24px !important; }
  .-mt-lg-only-26 {
    margin-top: 26px !important; }
  .-mt-lg-only-28 {
    margin-top: 28px !important; }
  .-mt-lg-only-30 {
    margin-top: 30px !important; }
  .-mt-lg-only-32 {
    margin-top: 32px !important; }
  .-mt-lg-only-34 {
    margin-top: 34px !important; }
  .-mt-lg-only-36 {
    margin-top: 36px !important; }
  .-mt-lg-only-38 {
    margin-top: 38px !important; }
  .-mt-lg-only-40 {
    margin-top: 40px !important; }
  .-mt-lg-only-42 {
    margin-top: 42px !important; }
  .-mt-lg-only-44 {
    margin-top: 44px !important; }
  .-mt-lg-only-46 {
    margin-top: 46px !important; }
  .-mt-lg-only-48 {
    margin-top: 48px !important; }
  .-mt-lg-only-50 {
    margin-top: 50px !important; }
  .-mt-lg-only-52 {
    margin-top: 52px !important; }
  .-mt-lg-only-54 {
    margin-top: 54px !important; }
  .-mt-lg-only-56 {
    margin-top: 56px !important; }
  .-mt-lg-only-58 {
    margin-top: 58px !important; }
  .-mt-lg-only-60 {
    margin-top: 60px !important; }
  .-mt-lg-only-62 {
    margin-top: 62px !important; }
  .-mt-lg-only-64 {
    margin-top: 64px !important; }
  .-mt-lg-only-66 {
    margin-top: 66px !important; }
  .-mt-lg-only-68 {
    margin-top: 68px !important; }
  .-mt-lg-only-70 {
    margin-top: 70px !important; }
  .-mt-lg-only-72 {
    margin-top: 72px !important; }
  .-mt-lg-only-74 {
    margin-top: 74px !important; }
  .-mt-lg-only-76 {
    margin-top: 76px !important; }
  .-mt-lg-only-78 {
    margin-top: 78px !important; }
  .-mt-lg-only-80 {
    margin-top: 80px !important; } }

@media (max-width: 1223px) {
  .-mt-lg-max-0 {
    margin-top: 0px !important; }
  .-mt-lg-max-1 {
    margin-top: 1px !important; }
  .-mt-lg-max-2 {
    margin-top: 2px !important; }
  .-mt-lg-max-3 {
    margin-top: 3px !important; }
  .-mt-lg-max-4 {
    margin-top: 4px !important; }
  .-mt-lg-max-5 {
    margin-top: 5px !important; }
  .-mt-lg-max-6 {
    margin-top: 6px !important; }
  .-mt-lg-max-7 {
    margin-top: 7px !important; }
  .-mt-lg-max-8 {
    margin-top: 8px !important; }
  .-mt-lg-max-9 {
    margin-top: 9px !important; }
  .-mt-lg-max-10 {
    margin-top: 10px !important; }
  .-mt-lg-max-11 {
    margin-top: 11px !important; }
  .-mt-lg-max-12 {
    margin-top: 12px !important; }
  .-mt-lg-max-14 {
    margin-top: 14px !important; }
  .-mt-lg-max-16 {
    margin-top: 16px !important; }
  .-mt-lg-max-18 {
    margin-top: 18px !important; }
  .-mt-lg-max-20 {
    margin-top: 20px !important; }
  .-mt-lg-max-22 {
    margin-top: 22px !important; }
  .-mt-lg-max-24 {
    margin-top: 24px !important; }
  .-mt-lg-max-26 {
    margin-top: 26px !important; }
  .-mt-lg-max-28 {
    margin-top: 28px !important; }
  .-mt-lg-max-30 {
    margin-top: 30px !important; }
  .-mt-lg-max-32 {
    margin-top: 32px !important; }
  .-mt-lg-max-34 {
    margin-top: 34px !important; }
  .-mt-lg-max-36 {
    margin-top: 36px !important; }
  .-mt-lg-max-38 {
    margin-top: 38px !important; }
  .-mt-lg-max-40 {
    margin-top: 40px !important; }
  .-mt-lg-max-42 {
    margin-top: 42px !important; }
  .-mt-lg-max-44 {
    margin-top: 44px !important; }
  .-mt-lg-max-46 {
    margin-top: 46px !important; }
  .-mt-lg-max-48 {
    margin-top: 48px !important; }
  .-mt-lg-max-50 {
    margin-top: 50px !important; }
  .-mt-lg-max-52 {
    margin-top: 52px !important; }
  .-mt-lg-max-54 {
    margin-top: 54px !important; }
  .-mt-lg-max-56 {
    margin-top: 56px !important; }
  .-mt-lg-max-58 {
    margin-top: 58px !important; }
  .-mt-lg-max-60 {
    margin-top: 60px !important; }
  .-mt-lg-max-62 {
    margin-top: 62px !important; }
  .-mt-lg-max-64 {
    margin-top: 64px !important; }
  .-mt-lg-max-66 {
    margin-top: 66px !important; }
  .-mt-lg-max-68 {
    margin-top: 68px !important; }
  .-mt-lg-max-70 {
    margin-top: 70px !important; }
  .-mt-lg-max-72 {
    margin-top: 72px !important; }
  .-mt-lg-max-74 {
    margin-top: 74px !important; }
  .-mt-lg-max-76 {
    margin-top: 76px !important; }
  .-mt-lg-max-78 {
    margin-top: 78px !important; }
  .-mt-lg-max-80 {
    margin-top: 80px !important; } }

@media (min-width: 1224px) {
  .-mt-xl-0 {
    margin-top: 0px !important; }
  .-mt-xl-1 {
    margin-top: 1px !important; }
  .-mt-xl-2 {
    margin-top: 2px !important; }
  .-mt-xl-3 {
    margin-top: 3px !important; }
  .-mt-xl-4 {
    margin-top: 4px !important; }
  .-mt-xl-5 {
    margin-top: 5px !important; }
  .-mt-xl-6 {
    margin-top: 6px !important; }
  .-mt-xl-7 {
    margin-top: 7px !important; }
  .-mt-xl-8 {
    margin-top: 8px !important; }
  .-mt-xl-9 {
    margin-top: 9px !important; }
  .-mt-xl-10 {
    margin-top: 10px !important; }
  .-mt-xl-11 {
    margin-top: 11px !important; }
  .-mt-xl-12 {
    margin-top: 12px !important; }
  .-mt-xl-14 {
    margin-top: 14px !important; }
  .-mt-xl-16 {
    margin-top: 16px !important; }
  .-mt-xl-18 {
    margin-top: 18px !important; }
  .-mt-xl-20 {
    margin-top: 20px !important; }
  .-mt-xl-22 {
    margin-top: 22px !important; }
  .-mt-xl-24 {
    margin-top: 24px !important; }
  .-mt-xl-26 {
    margin-top: 26px !important; }
  .-mt-xl-28 {
    margin-top: 28px !important; }
  .-mt-xl-30 {
    margin-top: 30px !important; }
  .-mt-xl-32 {
    margin-top: 32px !important; }
  .-mt-xl-34 {
    margin-top: 34px !important; }
  .-mt-xl-36 {
    margin-top: 36px !important; }
  .-mt-xl-38 {
    margin-top: 38px !important; }
  .-mt-xl-40 {
    margin-top: 40px !important; }
  .-mt-xl-42 {
    margin-top: 42px !important; }
  .-mt-xl-44 {
    margin-top: 44px !important; }
  .-mt-xl-46 {
    margin-top: 46px !important; }
  .-mt-xl-48 {
    margin-top: 48px !important; }
  .-mt-xl-50 {
    margin-top: 50px !important; }
  .-mt-xl-52 {
    margin-top: 52px !important; }
  .-mt-xl-54 {
    margin-top: 54px !important; }
  .-mt-xl-56 {
    margin-top: 56px !important; }
  .-mt-xl-58 {
    margin-top: 58px !important; }
  .-mt-xl-60 {
    margin-top: 60px !important; }
  .-mt-xl-62 {
    margin-top: 62px !important; }
  .-mt-xl-64 {
    margin-top: 64px !important; }
  .-mt-xl-66 {
    margin-top: 66px !important; }
  .-mt-xl-68 {
    margin-top: 68px !important; }
  .-mt-xl-70 {
    margin-top: 70px !important; }
  .-mt-xl-72 {
    margin-top: 72px !important; }
  .-mt-xl-74 {
    margin-top: 74px !important; }
  .-mt-xl-76 {
    margin-top: 76px !important; }
  .-mt-xl-78 {
    margin-top: 78px !important; }
  .-mt-xl-80 {
    margin-top: 80px !important; } }

/* Bottom
--------------------------------------------- */
.-mb-0 {
  margin-bottom: 0px !important; }

.-mb-1 {
  margin-bottom: 1px !important; }

.-mb-2 {
  margin-bottom: 2px !important; }

.-mb-3 {
  margin-bottom: 3px !important; }

.-mb-4 {
  margin-bottom: 4px !important; }

.-mb-5 {
  margin-bottom: 5px !important; }

.-mb-6 {
  margin-bottom: 6px !important; }

.-mb-7 {
  margin-bottom: 7px !important; }

.-mb-8 {
  margin-bottom: 8px !important; }

.-mb-9 {
  margin-bottom: 9px !important; }

.-mb-10 {
  margin-bottom: 10px !important; }

.-mb-11 {
  margin-bottom: 11px !important; }

.-mb-12 {
  margin-bottom: 12px !important; }

.-mb-14 {
  margin-bottom: 14px !important; }

.-mb-16 {
  margin-bottom: 16px !important; }

.-mb-18 {
  margin-bottom: 18px !important; }

.-mb-20 {
  margin-bottom: 20px !important; }

.-mb-22 {
  margin-bottom: 22px !important; }

.-mb-24 {
  margin-bottom: 24px !important; }

.-mb-26 {
  margin-bottom: 26px !important; }

.-mb-28 {
  margin-bottom: 28px !important; }

.-mb-30 {
  margin-bottom: 30px !important; }

.-mb-32 {
  margin-bottom: 32px !important; }

.-mb-34 {
  margin-bottom: 34px !important; }

.-mb-36 {
  margin-bottom: 36px !important; }

.-mb-38 {
  margin-bottom: 38px !important; }

.-mb-40 {
  margin-bottom: 40px !important; }

.-mb-42 {
  margin-bottom: 42px !important; }

.-mb-44 {
  margin-bottom: 44px !important; }

.-mb-46 {
  margin-bottom: 46px !important; }

.-mb-48 {
  margin-bottom: 48px !important; }

.-mb-50 {
  margin-bottom: 50px !important; }

.-mb-52 {
  margin-bottom: 52px !important; }

.-mb-54 {
  margin-bottom: 54px !important; }

.-mb-56 {
  margin-bottom: 56px !important; }

.-mb-58 {
  margin-bottom: 58px !important; }

.-mb-60 {
  margin-bottom: 60px !important; }

.-mb-62 {
  margin-bottom: 62px !important; }

.-mb-64 {
  margin-bottom: 64px !important; }

.-mb-66 {
  margin-bottom: 66px !important; }

.-mb-68 {
  margin-bottom: 68px !important; }

.-mb-70 {
  margin-bottom: 70px !important; }

.-mb-72 {
  margin-bottom: 72px !important; }

.-mb-74 {
  margin-bottom: 74px !important; }

.-mb-76 {
  margin-bottom: 76px !important; }

.-mb-78 {
  margin-bottom: 78px !important; }

.-mb-80 {
  margin-bottom: 80px !important; }

.-mb-82 {
  margin-bottom: 82px !important; }

.-mb-84 {
  margin-bottom: 84px !important; }

.-mb-86 {
  margin-bottom: 86px !important; }

.-mb-88 {
  margin-bottom: 88px !important; }

.-mb-90 {
  margin-bottom: 90px !important; }

.-mb-92 {
  margin-bottom: 92px !important; }

.-mb-94 {
  margin-bottom: 94px !important; }

.-mb-96 {
  margin-bottom: 96px !important; }

.-mb-neg-1 {
  margin-bottom: -1px !important; }

.-mb-neg-2 {
  margin-bottom: -2px !important; }

.-mb-neg-3 {
  margin-bottom: -3px !important; }

.-mb-neg-4 {
  margin-bottom: -4px !important; }

.-mb-neg-5 {
  margin-bottom: -5px !important; }

.-mb-neg-6 {
  margin-bottom: -6px !important; }

.-mb-neg-7 {
  margin-bottom: -7px !important; }

.-mb-neg-8 {
  margin-bottom: -8px !important; }

.-mb-neg-9 {
  margin-bottom: -9px !important; }

.-mb-neg-10 {
  margin-bottom: -10px !important; }

.-mb-neg-11 {
  margin-bottom: -11px !important; }

.-mb-neg-12 {
  margin-bottom: -12px !important; }

.-mb-neg-13 {
  margin-bottom: -13px !important; }

.-mb-neg-14 {
  margin-bottom: -14px !important; }

.-mb-neg-15 {
  margin-bottom: -15px !important; }

.-mb-neg-16 {
  margin-bottom: -16px !important; }

@media (max-width: 575px) {
  .-mb-xs-0 {
    margin-bottom: 0px !important; }
  .-mb-xs-1 {
    margin-bottom: 1px !important; }
  .-mb-xs-2 {
    margin-bottom: 2px !important; }
  .-mb-xs-3 {
    margin-bottom: 3px !important; }
  .-mb-xs-4 {
    margin-bottom: 4px !important; }
  .-mb-xs-5 {
    margin-bottom: 5px !important; }
  .-mb-xs-6 {
    margin-bottom: 6px !important; }
  .-mb-xs-7 {
    margin-bottom: 7px !important; }
  .-mb-xs-8 {
    margin-bottom: 8px !important; }
  .-mb-xs-9 {
    margin-bottom: 9px !important; }
  .-mb-xs-10 {
    margin-bottom: 10px !important; }
  .-mb-xs-11 {
    margin-bottom: 11px !important; }
  .-mb-xs-12 {
    margin-bottom: 12px !important; }
  .-mb-xs-14 {
    margin-bottom: 14px !important; }
  .-mb-xs-16 {
    margin-bottom: 16px !important; }
  .-mb-xs-18 {
    margin-bottom: 18px !important; }
  .-mb-xs-20 {
    margin-bottom: 20px !important; }
  .-mb-xs-22 {
    margin-bottom: 22px !important; }
  .-mb-xs-24 {
    margin-bottom: 24px !important; }
  .-mb-xs-26 {
    margin-bottom: 26px !important; }
  .-mb-xs-28 {
    margin-bottom: 28px !important; }
  .-mb-xs-30 {
    margin-bottom: 30px !important; }
  .-mb-xs-32 {
    margin-bottom: 32px !important; }
  .-mb-xs-34 {
    margin-bottom: 34px !important; }
  .-mb-xs-36 {
    margin-bottom: 36px !important; }
  .-mb-xs-38 {
    margin-bottom: 38px !important; }
  .-mb-xs-40 {
    margin-bottom: 40px !important; }
  .-mb-xs-42 {
    margin-bottom: 42px !important; }
  .-mb-xs-44 {
    margin-bottom: 44px !important; }
  .-mb-xs-46 {
    margin-bottom: 46px !important; }
  .-mb-xs-48 {
    margin-bottom: 48px !important; }
  .-mb-xs-50 {
    margin-bottom: 50px !important; }
  .-mb-xs-52 {
    margin-bottom: 52px !important; }
  .-mb-xs-54 {
    margin-bottom: 54px !important; }
  .-mb-xs-56 {
    margin-bottom: 56px !important; }
  .-mb-xs-58 {
    margin-bottom: 58px !important; }
  .-mb-xs-60 {
    margin-bottom: 60px !important; }
  .-mb-xs-62 {
    margin-bottom: 62px !important; }
  .-mb-xs-64 {
    margin-bottom: 64px !important; }
  .-mb-xs-66 {
    margin-bottom: 66px !important; }
  .-mb-xs-68 {
    margin-bottom: 68px !important; }
  .-mb-xs-70 {
    margin-bottom: 70px !important; }
  .-mb-xs-72 {
    margin-bottom: 72px !important; }
  .-mb-xs-74 {
    margin-bottom: 74px !important; }
  .-mb-xs-76 {
    margin-bottom: 76px !important; }
  .-mb-xs-78 {
    margin-bottom: 78px !important; }
  .-mb-xs-80 {
    margin-bottom: 80px !important; } }

@media (min-width: 576px) {
  .-mb-sm-0 {
    margin-bottom: 0px !important; }
  .-mb-sm-1 {
    margin-bottom: 1px !important; }
  .-mb-sm-2 {
    margin-bottom: 2px !important; }
  .-mb-sm-3 {
    margin-bottom: 3px !important; }
  .-mb-sm-4 {
    margin-bottom: 4px !important; }
  .-mb-sm-5 {
    margin-bottom: 5px !important; }
  .-mb-sm-6 {
    margin-bottom: 6px !important; }
  .-mb-sm-7 {
    margin-bottom: 7px !important; }
  .-mb-sm-8 {
    margin-bottom: 8px !important; }
  .-mb-sm-9 {
    margin-bottom: 9px !important; }
  .-mb-sm-10 {
    margin-bottom: 10px !important; }
  .-mb-sm-11 {
    margin-bottom: 11px !important; }
  .-mb-sm-12 {
    margin-bottom: 12px !important; }
  .-mb-sm-14 {
    margin-bottom: 14px !important; }
  .-mb-sm-16 {
    margin-bottom: 16px !important; }
  .-mb-sm-18 {
    margin-bottom: 18px !important; }
  .-mb-sm-20 {
    margin-bottom: 20px !important; }
  .-mb-sm-22 {
    margin-bottom: 22px !important; }
  .-mb-sm-24 {
    margin-bottom: 24px !important; }
  .-mb-sm-26 {
    margin-bottom: 26px !important; }
  .-mb-sm-28 {
    margin-bottom: 28px !important; }
  .-mb-sm-30 {
    margin-bottom: 30px !important; }
  .-mb-sm-32 {
    margin-bottom: 32px !important; }
  .-mb-sm-34 {
    margin-bottom: 34px !important; }
  .-mb-sm-36 {
    margin-bottom: 36px !important; }
  .-mb-sm-38 {
    margin-bottom: 38px !important; }
  .-mb-sm-40 {
    margin-bottom: 40px !important; }
  .-mb-sm-42 {
    margin-bottom: 42px !important; }
  .-mb-sm-44 {
    margin-bottom: 44px !important; }
  .-mb-sm-46 {
    margin-bottom: 46px !important; }
  .-mb-sm-48 {
    margin-bottom: 48px !important; }
  .-mb-sm-50 {
    margin-bottom: 50px !important; }
  .-mb-sm-52 {
    margin-bottom: 52px !important; }
  .-mb-sm-54 {
    margin-bottom: 54px !important; }
  .-mb-sm-56 {
    margin-bottom: 56px !important; }
  .-mb-sm-58 {
    margin-bottom: 58px !important; }
  .-mb-sm-60 {
    margin-bottom: 60px !important; }
  .-mb-sm-62 {
    margin-bottom: 62px !important; }
  .-mb-sm-64 {
    margin-bottom: 64px !important; }
  .-mb-sm-66 {
    margin-bottom: 66px !important; }
  .-mb-sm-68 {
    margin-bottom: 68px !important; }
  .-mb-sm-70 {
    margin-bottom: 70px !important; }
  .-mb-sm-72 {
    margin-bottom: 72px !important; }
  .-mb-sm-74 {
    margin-bottom: 74px !important; }
  .-mb-sm-76 {
    margin-bottom: 76px !important; }
  .-mb-sm-78 {
    margin-bottom: 78px !important; }
  .-mb-sm-80 {
    margin-bottom: 80px !important; } }

@media (max-width: 767px) {
  .-mb-sm-max-0 {
    margin-bottom: 0px !important; }
  .-mb-sm-max-1 {
    margin-bottom: 1px !important; }
  .-mb-sm-max-2 {
    margin-bottom: 2px !important; }
  .-mb-sm-max-3 {
    margin-bottom: 3px !important; }
  .-mb-sm-max-4 {
    margin-bottom: 4px !important; }
  .-mb-sm-max-5 {
    margin-bottom: 5px !important; }
  .-mb-sm-max-6 {
    margin-bottom: 6px !important; }
  .-mb-sm-max-7 {
    margin-bottom: 7px !important; }
  .-mb-sm-max-8 {
    margin-bottom: 8px !important; }
  .-mb-sm-max-9 {
    margin-bottom: 9px !important; }
  .-mb-sm-max-10 {
    margin-bottom: 10px !important; }
  .-mb-sm-max-11 {
    margin-bottom: 11px !important; }
  .-mb-sm-max-12 {
    margin-bottom: 12px !important; }
  .-mb-sm-max-14 {
    margin-bottom: 14px !important; }
  .-mb-sm-max-16 {
    margin-bottom: 16px !important; }
  .-mb-sm-max-18 {
    margin-bottom: 18px !important; }
  .-mb-sm-max-20 {
    margin-bottom: 20px !important; }
  .-mb-sm-max-22 {
    margin-bottom: 22px !important; }
  .-mb-sm-max-24 {
    margin-bottom: 24px !important; }
  .-mb-sm-max-26 {
    margin-bottom: 26px !important; }
  .-mb-sm-max-28 {
    margin-bottom: 28px !important; }
  .-mb-sm-max-30 {
    margin-bottom: 30px !important; }
  .-mb-sm-max-32 {
    margin-bottom: 32px !important; }
  .-mb-sm-max-34 {
    margin-bottom: 34px !important; }
  .-mb-sm-max-36 {
    margin-bottom: 36px !important; }
  .-mb-sm-max-38 {
    margin-bottom: 38px !important; }
  .-mb-sm-max-40 {
    margin-bottom: 40px !important; }
  .-mb-sm-max-42 {
    margin-bottom: 42px !important; }
  .-mb-sm-max-44 {
    margin-bottom: 44px !important; }
  .-mb-sm-max-46 {
    margin-bottom: 46px !important; }
  .-mb-sm-max-48 {
    margin-bottom: 48px !important; }
  .-mb-sm-max-50 {
    margin-bottom: 50px !important; }
  .-mb-sm-max-52 {
    margin-bottom: 52px !important; }
  .-mb-sm-max-54 {
    margin-bottom: 54px !important; }
  .-mb-sm-max-56 {
    margin-bottom: 56px !important; }
  .-mb-sm-max-58 {
    margin-bottom: 58px !important; }
  .-mb-sm-max-60 {
    margin-bottom: 60px !important; }
  .-mb-sm-max-62 {
    margin-bottom: 62px !important; }
  .-mb-sm-max-64 {
    margin-bottom: 64px !important; }
  .-mb-sm-max-66 {
    margin-bottom: 66px !important; }
  .-mb-sm-max-68 {
    margin-bottom: 68px !important; }
  .-mb-sm-max-70 {
    margin-bottom: 70px !important; }
  .-mb-sm-max-72 {
    margin-bottom: 72px !important; }
  .-mb-sm-max-74 {
    margin-bottom: 74px !important; }
  .-mb-sm-max-76 {
    margin-bottom: 76px !important; }
  .-mb-sm-max-78 {
    margin-bottom: 78px !important; }
  .-mb-sm-max-80 {
    margin-bottom: 80px !important; } }

@media (min-width: 768px) {
  .-mb-md-0 {
    margin-bottom: 0px !important; }
  .-mb-md-1 {
    margin-bottom: 1px !important; }
  .-mb-md-2 {
    margin-bottom: 2px !important; }
  .-mb-md-3 {
    margin-bottom: 3px !important; }
  .-mb-md-4 {
    margin-bottom: 4px !important; }
  .-mb-md-5 {
    margin-bottom: 5px !important; }
  .-mb-md-6 {
    margin-bottom: 6px !important; }
  .-mb-md-7 {
    margin-bottom: 7px !important; }
  .-mb-md-8 {
    margin-bottom: 8px !important; }
  .-mb-md-9 {
    margin-bottom: 9px !important; }
  .-mb-md-10 {
    margin-bottom: 10px !important; }
  .-mb-md-11 {
    margin-bottom: 11px !important; }
  .-mb-md-12 {
    margin-bottom: 12px !important; }
  .-mb-md-14 {
    margin-bottom: 14px !important; }
  .-mb-md-16 {
    margin-bottom: 16px !important; }
  .-mb-md-18 {
    margin-bottom: 18px !important; }
  .-mb-md-20 {
    margin-bottom: 20px !important; }
  .-mb-md-22 {
    margin-bottom: 22px !important; }
  .-mb-md-24 {
    margin-bottom: 24px !important; }
  .-mb-md-26 {
    margin-bottom: 26px !important; }
  .-mb-md-28 {
    margin-bottom: 28px !important; }
  .-mb-md-30 {
    margin-bottom: 30px !important; }
  .-mb-md-32 {
    margin-bottom: 32px !important; }
  .-mb-md-34 {
    margin-bottom: 34px !important; }
  .-mb-md-36 {
    margin-bottom: 36px !important; }
  .-mb-md-38 {
    margin-bottom: 38px !important; }
  .-mb-md-40 {
    margin-bottom: 40px !important; }
  .-mb-md-42 {
    margin-bottom: 42px !important; }
  .-mb-md-44 {
    margin-bottom: 44px !important; }
  .-mb-md-46 {
    margin-bottom: 46px !important; }
  .-mb-md-48 {
    margin-bottom: 48px !important; }
  .-mb-md-50 {
    margin-bottom: 50px !important; }
  .-mb-md-52 {
    margin-bottom: 52px !important; }
  .-mb-md-54 {
    margin-bottom: 54px !important; }
  .-mb-md-56 {
    margin-bottom: 56px !important; }
  .-mb-md-58 {
    margin-bottom: 58px !important; }
  .-mb-md-60 {
    margin-bottom: 60px !important; }
  .-mb-md-62 {
    margin-bottom: 62px !important; }
  .-mb-md-64 {
    margin-bottom: 64px !important; }
  .-mb-md-66 {
    margin-bottom: 66px !important; }
  .-mb-md-68 {
    margin-bottom: 68px !important; }
  .-mb-md-70 {
    margin-bottom: 70px !important; }
  .-mb-md-72 {
    margin-bottom: 72px !important; }
  .-mb-md-74 {
    margin-bottom: 74px !important; }
  .-mb-md-76 {
    margin-bottom: 76px !important; }
  .-mb-md-78 {
    margin-bottom: 78px !important; }
  .-mb-md-80 {
    margin-bottom: 80px !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .-mb-md-only-0 {
    margin-bottom: 0px !important; }
  .-mb-md-only-1 {
    margin-bottom: 1px !important; }
  .-mb-md-only-2 {
    margin-bottom: 2px !important; }
  .-mb-md-only-3 {
    margin-bottom: 3px !important; }
  .-mb-md-only-4 {
    margin-bottom: 4px !important; }
  .-mb-md-only-5 {
    margin-bottom: 5px !important; }
  .-mb-md-only-6 {
    margin-bottom: 6px !important; }
  .-mb-md-only-7 {
    margin-bottom: 7px !important; }
  .-mb-md-only-8 {
    margin-bottom: 8px !important; }
  .-mb-md-only-9 {
    margin-bottom: 9px !important; }
  .-mb-md-only-10 {
    margin-bottom: 10px !important; }
  .-mb-md-only-11 {
    margin-bottom: 11px !important; }
  .-mb-md-only-12 {
    margin-bottom: 12px !important; }
  .-mb-md-only-14 {
    margin-bottom: 14px !important; }
  .-mb-md-only-16 {
    margin-bottom: 16px !important; }
  .-mb-md-only-18 {
    margin-bottom: 18px !important; }
  .-mb-md-only-20 {
    margin-bottom: 20px !important; }
  .-mb-md-only-22 {
    margin-bottom: 22px !important; }
  .-mb-md-only-24 {
    margin-bottom: 24px !important; }
  .-mb-md-only-26 {
    margin-bottom: 26px !important; }
  .-mb-md-only-28 {
    margin-bottom: 28px !important; }
  .-mb-md-only-30 {
    margin-bottom: 30px !important; }
  .-mb-md-only-32 {
    margin-bottom: 32px !important; }
  .-mb-md-only-34 {
    margin-bottom: 34px !important; }
  .-mb-md-only-36 {
    margin-bottom: 36px !important; }
  .-mb-md-only-38 {
    margin-bottom: 38px !important; }
  .-mb-md-only-40 {
    margin-bottom: 40px !important; }
  .-mb-md-only-42 {
    margin-bottom: 42px !important; }
  .-mb-md-only-44 {
    margin-bottom: 44px !important; }
  .-mb-md-only-46 {
    margin-bottom: 46px !important; }
  .-mb-md-only-48 {
    margin-bottom: 48px !important; }
  .-mb-md-only-50 {
    margin-bottom: 50px !important; }
  .-mb-md-only-52 {
    margin-bottom: 52px !important; }
  .-mb-md-only-54 {
    margin-bottom: 54px !important; }
  .-mb-md-only-56 {
    margin-bottom: 56px !important; }
  .-mb-md-only-58 {
    margin-bottom: 58px !important; }
  .-mb-md-only-60 {
    margin-bottom: 60px !important; }
  .-mb-md-only-62 {
    margin-bottom: 62px !important; }
  .-mb-md-only-64 {
    margin-bottom: 64px !important; }
  .-mb-md-only-66 {
    margin-bottom: 66px !important; }
  .-mb-md-only-68 {
    margin-bottom: 68px !important; }
  .-mb-md-only-70 {
    margin-bottom: 70px !important; }
  .-mb-md-only-72 {
    margin-bottom: 72px !important; }
  .-mb-md-only-74 {
    margin-bottom: 74px !important; }
  .-mb-md-only-76 {
    margin-bottom: 76px !important; }
  .-mb-md-only-78 {
    margin-bottom: 78px !important; }
  .-mb-md-only-80 {
    margin-bottom: 80px !important; } }

@media (max-width: 991px) {
  .-mb-md-max-0 {
    margin-bottom: 0px !important; }
  .-mb-md-max-1 {
    margin-bottom: 1px !important; }
  .-mb-md-max-2 {
    margin-bottom: 2px !important; }
  .-mb-md-max-3 {
    margin-bottom: 3px !important; }
  .-mb-md-max-4 {
    margin-bottom: 4px !important; }
  .-mb-md-max-5 {
    margin-bottom: 5px !important; }
  .-mb-md-max-6 {
    margin-bottom: 6px !important; }
  .-mb-md-max-7 {
    margin-bottom: 7px !important; }
  .-mb-md-max-8 {
    margin-bottom: 8px !important; }
  .-mb-md-max-9 {
    margin-bottom: 9px !important; }
  .-mb-md-max-10 {
    margin-bottom: 10px !important; }
  .-mb-md-max-11 {
    margin-bottom: 11px !important; }
  .-mb-md-max-12 {
    margin-bottom: 12px !important; }
  .-mb-md-max-14 {
    margin-bottom: 14px !important; }
  .-mb-md-max-16 {
    margin-bottom: 16px !important; }
  .-mb-md-max-18 {
    margin-bottom: 18px !important; }
  .-mb-md-max-20 {
    margin-bottom: 20px !important; }
  .-mb-md-max-22 {
    margin-bottom: 22px !important; }
  .-mb-md-max-24 {
    margin-bottom: 24px !important; }
  .-mb-md-max-26 {
    margin-bottom: 26px !important; }
  .-mb-md-max-28 {
    margin-bottom: 28px !important; }
  .-mb-md-max-30 {
    margin-bottom: 30px !important; }
  .-mb-md-max-32 {
    margin-bottom: 32px !important; }
  .-mb-md-max-34 {
    margin-bottom: 34px !important; }
  .-mb-md-max-36 {
    margin-bottom: 36px !important; }
  .-mb-md-max-38 {
    margin-bottom: 38px !important; }
  .-mb-md-max-40 {
    margin-bottom: 40px !important; }
  .-mb-md-max-42 {
    margin-bottom: 42px !important; }
  .-mb-md-max-44 {
    margin-bottom: 44px !important; }
  .-mb-md-max-46 {
    margin-bottom: 46px !important; }
  .-mb-md-max-48 {
    margin-bottom: 48px !important; }
  .-mb-md-max-50 {
    margin-bottom: 50px !important; }
  .-mb-md-max-52 {
    margin-bottom: 52px !important; }
  .-mb-md-max-54 {
    margin-bottom: 54px !important; }
  .-mb-md-max-56 {
    margin-bottom: 56px !important; }
  .-mb-md-max-58 {
    margin-bottom: 58px !important; }
  .-mb-md-max-60 {
    margin-bottom: 60px !important; }
  .-mb-md-max-62 {
    margin-bottom: 62px !important; }
  .-mb-md-max-64 {
    margin-bottom: 64px !important; }
  .-mb-md-max-66 {
    margin-bottom: 66px !important; }
  .-mb-md-max-68 {
    margin-bottom: 68px !important; }
  .-mb-md-max-70 {
    margin-bottom: 70px !important; }
  .-mb-md-max-72 {
    margin-bottom: 72px !important; }
  .-mb-md-max-74 {
    margin-bottom: 74px !important; }
  .-mb-md-max-76 {
    margin-bottom: 76px !important; }
  .-mb-md-max-78 {
    margin-bottom: 78px !important; }
  .-mb-md-max-80 {
    margin-bottom: 80px !important; } }

@media (min-width: 992px) {
  .-mb-lg-0 {
    margin-bottom: 0px !important; }
  .-mb-lg-1 {
    margin-bottom: 1px !important; }
  .-mb-lg-2 {
    margin-bottom: 2px !important; }
  .-mb-lg-3 {
    margin-bottom: 3px !important; }
  .-mb-lg-4 {
    margin-bottom: 4px !important; }
  .-mb-lg-5 {
    margin-bottom: 5px !important; }
  .-mb-lg-6 {
    margin-bottom: 6px !important; }
  .-mb-lg-7 {
    margin-bottom: 7px !important; }
  .-mb-lg-8 {
    margin-bottom: 8px !important; }
  .-mb-lg-9 {
    margin-bottom: 9px !important; }
  .-mb-lg-10 {
    margin-bottom: 10px !important; }
  .-mb-lg-11 {
    margin-bottom: 11px !important; }
  .-mb-lg-12 {
    margin-bottom: 12px !important; }
  .-mb-lg-14 {
    margin-bottom: 14px !important; }
  .-mb-lg-16 {
    margin-bottom: 16px !important; }
  .-mb-lg-18 {
    margin-bottom: 18px !important; }
  .-mb-lg-20 {
    margin-bottom: 20px !important; }
  .-mb-lg-22 {
    margin-bottom: 22px !important; }
  .-mb-lg-24 {
    margin-bottom: 24px !important; }
  .-mb-lg-26 {
    margin-bottom: 26px !important; }
  .-mb-lg-28 {
    margin-bottom: 28px !important; }
  .-mb-lg-30 {
    margin-bottom: 30px !important; }
  .-mb-lg-32 {
    margin-bottom: 32px !important; }
  .-mb-lg-34 {
    margin-bottom: 34px !important; }
  .-mb-lg-36 {
    margin-bottom: 36px !important; }
  .-mb-lg-38 {
    margin-bottom: 38px !important; }
  .-mb-lg-40 {
    margin-bottom: 40px !important; }
  .-mb-lg-42 {
    margin-bottom: 42px !important; }
  .-mb-lg-44 {
    margin-bottom: 44px !important; }
  .-mb-lg-46 {
    margin-bottom: 46px !important; }
  .-mb-lg-48 {
    margin-bottom: 48px !important; }
  .-mb-lg-50 {
    margin-bottom: 50px !important; }
  .-mb-lg-52 {
    margin-bottom: 52px !important; }
  .-mb-lg-54 {
    margin-bottom: 54px !important; }
  .-mb-lg-56 {
    margin-bottom: 56px !important; }
  .-mb-lg-58 {
    margin-bottom: 58px !important; }
  .-mb-lg-60 {
    margin-bottom: 60px !important; }
  .-mb-lg-62 {
    margin-bottom: 62px !important; }
  .-mb-lg-64 {
    margin-bottom: 64px !important; }
  .-mb-lg-66 {
    margin-bottom: 66px !important; }
  .-mb-lg-68 {
    margin-bottom: 68px !important; }
  .-mb-lg-70 {
    margin-bottom: 70px !important; }
  .-mb-lg-72 {
    margin-bottom: 72px !important; }
  .-mb-lg-74 {
    margin-bottom: 74px !important; }
  .-mb-lg-76 {
    margin-bottom: 76px !important; }
  .-mb-lg-78 {
    margin-bottom: 78px !important; }
  .-mb-lg-80 {
    margin-bottom: 80px !important; } }

@media (min-width: 992px) and (max-width: 1223px) {
  .-mb-lg-only-0 {
    margin-bottom: 0px !important; }
  .-mb-lg-only-1 {
    margin-bottom: 1px !important; }
  .-mb-lg-only-2 {
    margin-bottom: 2px !important; }
  .-mb-lg-only-3 {
    margin-bottom: 3px !important; }
  .-mb-lg-only-4 {
    margin-bottom: 4px !important; }
  .-mb-lg-only-5 {
    margin-bottom: 5px !important; }
  .-mb-lg-only-6 {
    margin-bottom: 6px !important; }
  .-mb-lg-only-7 {
    margin-bottom: 7px !important; }
  .-mb-lg-only-8 {
    margin-bottom: 8px !important; }
  .-mb-lg-only-9 {
    margin-bottom: 9px !important; }
  .-mb-lg-only-10 {
    margin-bottom: 10px !important; }
  .-mb-lg-only-11 {
    margin-bottom: 11px !important; }
  .-mb-lg-only-12 {
    margin-bottom: 12px !important; }
  .-mb-lg-only-14 {
    margin-bottom: 14px !important; }
  .-mb-lg-only-16 {
    margin-bottom: 16px !important; }
  .-mb-lg-only-18 {
    margin-bottom: 18px !important; }
  .-mb-lg-only-20 {
    margin-bottom: 20px !important; }
  .-mb-lg-only-22 {
    margin-bottom: 22px !important; }
  .-mb-lg-only-24 {
    margin-bottom: 24px !important; }
  .-mb-lg-only-26 {
    margin-bottom: 26px !important; }
  .-mb-lg-only-28 {
    margin-bottom: 28px !important; }
  .-mb-lg-only-30 {
    margin-bottom: 30px !important; }
  .-mb-lg-only-32 {
    margin-bottom: 32px !important; }
  .-mb-lg-only-34 {
    margin-bottom: 34px !important; }
  .-mb-lg-only-36 {
    margin-bottom: 36px !important; }
  .-mb-lg-only-38 {
    margin-bottom: 38px !important; }
  .-mb-lg-only-40 {
    margin-bottom: 40px !important; }
  .-mb-lg-only-42 {
    margin-bottom: 42px !important; }
  .-mb-lg-only-44 {
    margin-bottom: 44px !important; }
  .-mb-lg-only-46 {
    margin-bottom: 46px !important; }
  .-mb-lg-only-48 {
    margin-bottom: 48px !important; }
  .-mb-lg-only-50 {
    margin-bottom: 50px !important; }
  .-mb-lg-only-52 {
    margin-bottom: 52px !important; }
  .-mb-lg-only-54 {
    margin-bottom: 54px !important; }
  .-mb-lg-only-56 {
    margin-bottom: 56px !important; }
  .-mb-lg-only-58 {
    margin-bottom: 58px !important; }
  .-mb-lg-only-60 {
    margin-bottom: 60px !important; }
  .-mb-lg-only-62 {
    margin-bottom: 62px !important; }
  .-mb-lg-only-64 {
    margin-bottom: 64px !important; }
  .-mb-lg-only-66 {
    margin-bottom: 66px !important; }
  .-mb-lg-only-68 {
    margin-bottom: 68px !important; }
  .-mb-lg-only-70 {
    margin-bottom: 70px !important; }
  .-mb-lg-only-72 {
    margin-bottom: 72px !important; }
  .-mb-lg-only-74 {
    margin-bottom: 74px !important; }
  .-mb-lg-only-76 {
    margin-bottom: 76px !important; }
  .-mb-lg-only-78 {
    margin-bottom: 78px !important; }
  .-mb-lg-only-80 {
    margin-bottom: 80px !important; } }

@media (max-width: 1223px) {
  .-mb-lg-max-0 {
    margin-bottom: 0px !important; }
  .-mb-lg-max-1 {
    margin-bottom: 1px !important; }
  .-mb-lg-max-2 {
    margin-bottom: 2px !important; }
  .-mb-lg-max-3 {
    margin-bottom: 3px !important; }
  .-mb-lg-max-4 {
    margin-bottom: 4px !important; }
  .-mb-lg-max-5 {
    margin-bottom: 5px !important; }
  .-mb-lg-max-6 {
    margin-bottom: 6px !important; }
  .-mb-lg-max-7 {
    margin-bottom: 7px !important; }
  .-mb-lg-max-8 {
    margin-bottom: 8px !important; }
  .-mb-lg-max-9 {
    margin-bottom: 9px !important; }
  .-mb-lg-max-10 {
    margin-bottom: 10px !important; }
  .-mb-lg-max-11 {
    margin-bottom: 11px !important; }
  .-mb-lg-max-12 {
    margin-bottom: 12px !important; }
  .-mb-lg-max-14 {
    margin-bottom: 14px !important; }
  .-mb-lg-max-16 {
    margin-bottom: 16px !important; }
  .-mb-lg-max-18 {
    margin-bottom: 18px !important; }
  .-mb-lg-max-20 {
    margin-bottom: 20px !important; }
  .-mb-lg-max-22 {
    margin-bottom: 22px !important; }
  .-mb-lg-max-24 {
    margin-bottom: 24px !important; }
  .-mb-lg-max-26 {
    margin-bottom: 26px !important; }
  .-mb-lg-max-28 {
    margin-bottom: 28px !important; }
  .-mb-lg-max-30 {
    margin-bottom: 30px !important; }
  .-mb-lg-max-32 {
    margin-bottom: 32px !important; }
  .-mb-lg-max-34 {
    margin-bottom: 34px !important; }
  .-mb-lg-max-36 {
    margin-bottom: 36px !important; }
  .-mb-lg-max-38 {
    margin-bottom: 38px !important; }
  .-mb-lg-max-40 {
    margin-bottom: 40px !important; }
  .-mb-lg-max-42 {
    margin-bottom: 42px !important; }
  .-mb-lg-max-44 {
    margin-bottom: 44px !important; }
  .-mb-lg-max-46 {
    margin-bottom: 46px !important; }
  .-mb-lg-max-48 {
    margin-bottom: 48px !important; }
  .-mb-lg-max-50 {
    margin-bottom: 50px !important; }
  .-mb-lg-max-52 {
    margin-bottom: 52px !important; }
  .-mb-lg-max-54 {
    margin-bottom: 54px !important; }
  .-mb-lg-max-56 {
    margin-bottom: 56px !important; }
  .-mb-lg-max-58 {
    margin-bottom: 58px !important; }
  .-mb-lg-max-60 {
    margin-bottom: 60px !important; }
  .-mb-lg-max-62 {
    margin-bottom: 62px !important; }
  .-mb-lg-max-64 {
    margin-bottom: 64px !important; }
  .-mb-lg-max-66 {
    margin-bottom: 66px !important; }
  .-mb-lg-max-68 {
    margin-bottom: 68px !important; }
  .-mb-lg-max-70 {
    margin-bottom: 70px !important; }
  .-mb-lg-max-72 {
    margin-bottom: 72px !important; }
  .-mb-lg-max-74 {
    margin-bottom: 74px !important; }
  .-mb-lg-max-76 {
    margin-bottom: 76px !important; }
  .-mb-lg-max-78 {
    margin-bottom: 78px !important; }
  .-mb-lg-max-80 {
    margin-bottom: 80px !important; } }

@media (min-width: 1224px) {
  .-mb-xl-0 {
    margin-bottom: 0px !important; }
  .-mb-xl-1 {
    margin-bottom: 1px !important; }
  .-mb-xl-2 {
    margin-bottom: 2px !important; }
  .-mb-xl-3 {
    margin-bottom: 3px !important; }
  .-mb-xl-4 {
    margin-bottom: 4px !important; }
  .-mb-xl-5 {
    margin-bottom: 5px !important; }
  .-mb-xl-6 {
    margin-bottom: 6px !important; }
  .-mb-xl-7 {
    margin-bottom: 7px !important; }
  .-mb-xl-8 {
    margin-bottom: 8px !important; }
  .-mb-xl-9 {
    margin-bottom: 9px !important; }
  .-mb-xl-10 {
    margin-bottom: 10px !important; }
  .-mb-xl-11 {
    margin-bottom: 11px !important; }
  .-mb-xl-12 {
    margin-bottom: 12px !important; }
  .-mb-xl-14 {
    margin-bottom: 14px !important; }
  .-mb-xl-16 {
    margin-bottom: 16px !important; }
  .-mb-xl-18 {
    margin-bottom: 18px !important; }
  .-mb-xl-20 {
    margin-bottom: 20px !important; }
  .-mb-xl-22 {
    margin-bottom: 22px !important; }
  .-mb-xl-24 {
    margin-bottom: 24px !important; }
  .-mb-xl-26 {
    margin-bottom: 26px !important; }
  .-mb-xl-28 {
    margin-bottom: 28px !important; }
  .-mb-xl-30 {
    margin-bottom: 30px !important; }
  .-mb-xl-32 {
    margin-bottom: 32px !important; }
  .-mb-xl-34 {
    margin-bottom: 34px !important; }
  .-mb-xl-36 {
    margin-bottom: 36px !important; }
  .-mb-xl-38 {
    margin-bottom: 38px !important; }
  .-mb-xl-40 {
    margin-bottom: 40px !important; }
  .-mb-xl-42 {
    margin-bottom: 42px !important; }
  .-mb-xl-44 {
    margin-bottom: 44px !important; }
  .-mb-xl-46 {
    margin-bottom: 46px !important; }
  .-mb-xl-48 {
    margin-bottom: 48px !important; }
  .-mb-xl-50 {
    margin-bottom: 50px !important; }
  .-mb-xl-52 {
    margin-bottom: 52px !important; }
  .-mb-xl-54 {
    margin-bottom: 54px !important; }
  .-mb-xl-56 {
    margin-bottom: 56px !important; }
  .-mb-xl-58 {
    margin-bottom: 58px !important; }
  .-mb-xl-60 {
    margin-bottom: 60px !important; }
  .-mb-xl-62 {
    margin-bottom: 62px !important; }
  .-mb-xl-64 {
    margin-bottom: 64px !important; }
  .-mb-xl-66 {
    margin-bottom: 66px !important; }
  .-mb-xl-68 {
    margin-bottom: 68px !important; }
  .-mb-xl-70 {
    margin-bottom: 70px !important; }
  .-mb-xl-72 {
    margin-bottom: 72px !important; }
  .-mb-xl-74 {
    margin-bottom: 74px !important; }
  .-mb-xl-76 {
    margin-bottom: 76px !important; }
  .-mb-xl-78 {
    margin-bottom: 78px !important; }
  .-mb-xl-80 {
    margin-bottom: 80px !important; } }

/*------------------------------------------------------------------------------
Padding Adjustments
-------------------------------------------------------------------------------- */
/* All Sides
--------------------------------------------4 */
.-p-0 {
  padding: 0px !important; }

.-p-4 {
  padding: 4px !important; }

.-p-8 {
  padding: 8px !important; }

.-p-12 {
  padding: 12px !important; }

.-p-16 {
  padding: 16px !important; }

.-p-20 {
  padding: 20px !important; }

.-p-24 {
  padding: 24px !important; }

.-p-28 {
  padding: 28px !important; }

.-p-32 {
  padding: 32px !important; }

.-p-36 {
  padding: 36px !important; }

.-p-40 {
  padding: 40px !important; }

.-p-44 {
  padding: 44px !important; }

.-p-48 {
  padding: 48px !important; }

.-p-52 {
  padding: 52px !important; }

.-p-56 {
  padding: 56px !important; }

.-p-60 {
  padding: 60px !important; }

.-p-64 {
  padding: 64px !important; }

.-p-68 {
  padding: 68px !important; }

.-p-72 {
  padding: 72px !important; }

.-p-76 {
  padding: 76px !important; }

.-p-80 {
  padding: 80px !important; }

/* Top
--------------------------------------------4 */
.-pt-32 {
  padding-top: 32px; }

.-pt-56 {
  padding-top: 56px; }

/* Left
--------------------------------------------4 */
.-pl-20 {
  padding-left: 20px; }

/* Right
--------------------------------------------4 */
.-pr-2 {
  padding-right: 2px; }

.-pr-24 {
  padding-right: 24px; }

/*------------------------------------------------------------------------------
Height Adjustments
-------------------------------------------------------------------------------- */
.-h-100vh {
  height: 100vh !important; }

/*------------------------------------------------------------------------------
Width Adjustments
-------------------------------------------------------------------------------- */
.-minw-1 {
  min-width: 1px !important; }

.-minw-2 {
  min-width: 2px !important; }

.-minw-3 {
  min-width: 3px !important; }

.-minw-4 {
  min-width: 4px !important; }

.-minw-5 {
  min-width: 5px !important; }

.-minw-6 {
  min-width: 6px !important; }

.-minw-7 {
  min-width: 7px !important; }

.-minw-8 {
  min-width: 8px !important; }

.-minw-9 {
  min-width: 9px !important; }

.-minw-10 {
  min-width: 10px !important; }

.-minw-11 {
  min-width: 11px !important; }

.-minw-12 {
  min-width: 12px !important; }

.-minw-13 {
  min-width: 13px !important; }

.-minw-14 {
  min-width: 14px !important; }

.-minw-15 {
  min-width: 15px !important; }

.-minw-16 {
  min-width: 16px !important; }

.-minw-17 {
  min-width: 17px !important; }

.-minw-18 {
  min-width: 18px !important; }

.-minw-19 {
  min-width: 19px !important; }

.-minw-20 {
  min-width: 20px !important; }

.-minw-21 {
  min-width: 21px !important; }

.-minw-22 {
  min-width: 22px !important; }

.-minw-23 {
  min-width: 23px !important; }

.-minw-24 {
  min-width: 24px !important; }

.-minw-25 {
  min-width: 25px !important; }

.-minw-26 {
  min-width: 26px !important; }

.-minw-27 {
  min-width: 27px !important; }

.-minw-28 {
  min-width: 28px !important; }

.-minw-29 {
  min-width: 29px !important; }

.-minw-30 {
  min-width: 30px !important; }

.-minw-31 {
  min-width: 31px !important; }

.-minw-32 {
  min-width: 32px !important; }

.-minw-33 {
  min-width: 33px !important; }

.-minw-34 {
  min-width: 34px !important; }

.-minw-35 {
  min-width: 35px !important; }

.-minw-36 {
  min-width: 36px !important; }

.-minw-37 {
  min-width: 37px !important; }

.-minw-38 {
  min-width: 38px !important; }

.-minw-39 {
  min-width: 39px !important; }

.-minw-40 {
  min-width: 40px !important; }

.-minw-41 {
  min-width: 41px !important; }

.-minw-42 {
  min-width: 42px !important; }

.-minw-43 {
  min-width: 43px !important; }

.-minw-44 {
  min-width: 44px !important; }

.-minw-45 {
  min-width: 45px !important; }

.-minw-46 {
  min-width: 46px !important; }

.-minw-47 {
  min-width: 47px !important; }

.-minw-48 {
  min-width: 48px !important; }

.-minw-49 {
  min-width: 49px !important; }

.-minw-50 {
  min-width: 50px !important; }

.-minw-51 {
  min-width: 51px !important; }

.-minw-52 {
  min-width: 52px !important; }

.-minw-53 {
  min-width: 53px !important; }

.-minw-54 {
  min-width: 54px !important; }

.-minw-55 {
  min-width: 55px !important; }

.-minw-56 {
  min-width: 56px !important; }

.-minw-57 {
  min-width: 57px !important; }

.-minw-58 {
  min-width: 58px !important; }

.-minw-59 {
  min-width: 59px !important; }

.-minw-60 {
  min-width: 60px !important; }

.-minw-61 {
  min-width: 61px !important; }

.-minw-62 {
  min-width: 62px !important; }

.-minw-63 {
  min-width: 63px !important; }

.-minw-64 {
  min-width: 64px !important; }

.-minw-65 {
  min-width: 65px !important; }

.-minw-66 {
  min-width: 66px !important; }

.-minw-67 {
  min-width: 67px !important; }

.-minw-68 {
  min-width: 68px !important; }

.-minw-69 {
  min-width: 69px !important; }

.-minw-70 {
  min-width: 70px !important; }

.-minw-71 {
  min-width: 71px !important; }

.-minw-72 {
  min-width: 72px !important; }

.-minw-73 {
  min-width: 73px !important; }

.-minw-74 {
  min-width: 74px !important; }

.-minw-75 {
  min-width: 75px !important; }

.-minw-76 {
  min-width: 76px !important; }

.-minw-77 {
  min-width: 77px !important; }

.-minw-78 {
  min-width: 78px !important; }

.-minw-79 {
  min-width: 79px !important; }

.-minw-80 {
  min-width: 80px !important; }

.-minw-81 {
  min-width: 81px !important; }

.-minw-82 {
  min-width: 82px !important; }

.-minw-83 {
  min-width: 83px !important; }

.-minw-84 {
  min-width: 84px !important; }

.-minw-85 {
  min-width: 85px !important; }

.-minw-86 {
  min-width: 86px !important; }

.-minw-87 {
  min-width: 87px !important; }

.-minw-88 {
  min-width: 88px !important; }

.-minw-89 {
  min-width: 89px !important; }

.-minw-90 {
  min-width: 90px !important; }

.-minw-91 {
  min-width: 91px !important; }

.-minw-92 {
  min-width: 92px !important; }

.-minw-93 {
  min-width: 93px !important; }

.-minw-94 {
  min-width: 94px !important; }

.-minw-95 {
  min-width: 95px !important; }

.-minw-96 {
  min-width: 96px !important; }

.-minw-97 {
  min-width: 97px !important; }

.-minw-98 {
  min-width: 98px !important; }

.-minw-99 {
  min-width: 99px !important; }

.-minw-100 {
  min-width: 100px !important; }

.-minw-101 {
  min-width: 101px !important; }

.-minw-102 {
  min-width: 102px !important; }

.-minw-103 {
  min-width: 103px !important; }

.-minw-104 {
  min-width: 104px !important; }

.-minw-105 {
  min-width: 105px !important; }

.-minw-106 {
  min-width: 106px !important; }

.-minw-107 {
  min-width: 107px !important; }

.-minw-108 {
  min-width: 108px !important; }

.-minw-109 {
  min-width: 109px !important; }

.-minw-110 {
  min-width: 110px !important; }

.-minw-111 {
  min-width: 111px !important; }

.-minw-112 {
  min-width: 112px !important; }

.-minw-113 {
  min-width: 113px !important; }

.-minw-114 {
  min-width: 114px !important; }

.-minw-115 {
  min-width: 115px !important; }

.-minw-116 {
  min-width: 116px !important; }

.-minw-117 {
  min-width: 117px !important; }

.-minw-118 {
  min-width: 118px !important; }

.-minw-119 {
  min-width: 119px !important; }

.-minw-120 {
  min-width: 120px !important; }

.-minw-121 {
  min-width: 121px !important; }

.-minw-122 {
  min-width: 122px !important; }

.-minw-123 {
  min-width: 123px !important; }

.-minw-124 {
  min-width: 124px !important; }

.-minw-125 {
  min-width: 125px !important; }

.-minw-126 {
  min-width: 126px !important; }

.-minw-127 {
  min-width: 127px !important; }

.-minw-128 {
  min-width: 128px !important; }

.-minw-129 {
  min-width: 129px !important; }

.-minw-130 {
  min-width: 130px !important; }

.-minw-131 {
  min-width: 131px !important; }

.-minw-132 {
  min-width: 132px !important; }

.-minw-133 {
  min-width: 133px !important; }

.-minw-134 {
  min-width: 134px !important; }

.-minw-135 {
  min-width: 135px !important; }

.-minw-136 {
  min-width: 136px !important; }

.-minw-137 {
  min-width: 137px !important; }

.-minw-138 {
  min-width: 138px !important; }

.-minw-139 {
  min-width: 139px !important; }

.-minw-140 {
  min-width: 140px !important; }

.-minw-141 {
  min-width: 141px !important; }

.-minw-142 {
  min-width: 142px !important; }

.-minw-143 {
  min-width: 143px !important; }

.-minw-144 {
  min-width: 144px !important; }

.-minw-145 {
  min-width: 145px !important; }

.-minw-146 {
  min-width: 146px !important; }

.-minw-147 {
  min-width: 147px !important; }

.-minw-148 {
  min-width: 148px !important; }

.-minw-149 {
  min-width: 149px !important; }

.-minw-150 {
  min-width: 150px !important; }

.-minw-151 {
  min-width: 151px !important; }

.-minw-152 {
  min-width: 152px !important; }

.-minw-153 {
  min-width: 153px !important; }

.-minw-154 {
  min-width: 154px !important; }

.-minw-155 {
  min-width: 155px !important; }

.-minw-156 {
  min-width: 156px !important; }

.-minw-157 {
  min-width: 157px !important; }

.-minw-158 {
  min-width: 158px !important; }

.-minw-159 {
  min-width: 159px !important; }

.-minw-160 {
  min-width: 160px !important; }

.-minw-161 {
  min-width: 161px !important; }

.-minw-162 {
  min-width: 162px !important; }

.-minw-163 {
  min-width: 163px !important; }

.-minw-164 {
  min-width: 164px !important; }

.-minw-165 {
  min-width: 165px !important; }

.-minw-166 {
  min-width: 166px !important; }

.-minw-167 {
  min-width: 167px !important; }

.-minw-168 {
  min-width: 168px !important; }

.-minw-169 {
  min-width: 169px !important; }

.-minw-170 {
  min-width: 170px !important; }

.-minw-171 {
  min-width: 171px !important; }

.-minw-172 {
  min-width: 172px !important; }

.-minw-173 {
  min-width: 173px !important; }

.-minw-174 {
  min-width: 174px !important; }

.-minw-175 {
  min-width: 175px !important; }

.-minw-176 {
  min-width: 176px !important; }

.-minw-177 {
  min-width: 177px !important; }

.-minw-178 {
  min-width: 178px !important; }

.-minw-179 {
  min-width: 179px !important; }

.-minw-180 {
  min-width: 180px !important; }

.-minw-181 {
  min-width: 181px !important; }

.-minw-182 {
  min-width: 182px !important; }

.-minw-183 {
  min-width: 183px !important; }

.-minw-184 {
  min-width: 184px !important; }

.-minw-185 {
  min-width: 185px !important; }

.-minw-186 {
  min-width: 186px !important; }

.-minw-187 {
  min-width: 187px !important; }

.-minw-188 {
  min-width: 188px !important; }

.-minw-189 {
  min-width: 189px !important; }

.-minw-190 {
  min-width: 190px !important; }

.-minw-191 {
  min-width: 191px !important; }

.-minw-192 {
  min-width: 192px !important; }

.-minw-193 {
  min-width: 193px !important; }

.-minw-194 {
  min-width: 194px !important; }

.-minw-195 {
  min-width: 195px !important; }

.-minw-196 {
  min-width: 196px !important; }

.-minw-197 {
  min-width: 197px !important; }

.-minw-198 {
  min-width: 198px !important; }

.-minw-199 {
  min-width: 199px !important; }

.-minw-200 {
  min-width: 200px !important; }

.-minw-201 {
  min-width: 201px !important; }

.-minw-202 {
  min-width: 202px !important; }

.-minw-203 {
  min-width: 203px !important; }

.-minw-204 {
  min-width: 204px !important; }

.-minw-205 {
  min-width: 205px !important; }

.-minw-206 {
  min-width: 206px !important; }

.-minw-207 {
  min-width: 207px !important; }

.-minw-208 {
  min-width: 208px !important; }

.-minw-209 {
  min-width: 209px !important; }

.-minw-210 {
  min-width: 210px !important; }

.-minw-211 {
  min-width: 211px !important; }

.-minw-212 {
  min-width: 212px !important; }

.-minw-213 {
  min-width: 213px !important; }

.-minw-214 {
  min-width: 214px !important; }

.-minw-215 {
  min-width: 215px !important; }

.-minw-216 {
  min-width: 216px !important; }

.-minw-217 {
  min-width: 217px !important; }

.-minw-218 {
  min-width: 218px !important; }

.-minw-219 {
  min-width: 219px !important; }

.-minw-220 {
  min-width: 220px !important; }

.-minw-221 {
  min-width: 221px !important; }

.-minw-222 {
  min-width: 222px !important; }

.-minw-223 {
  min-width: 223px !important; }

.-minw-224 {
  min-width: 224px !important; }

.-minw-225 {
  min-width: 225px !important; }

.-minw-226 {
  min-width: 226px !important; }

.-minw-227 {
  min-width: 227px !important; }

.-minw-228 {
  min-width: 228px !important; }

.-minw-229 {
  min-width: 229px !important; }

.-minw-230 {
  min-width: 230px !important; }

.-minw-231 {
  min-width: 231px !important; }

.-minw-232 {
  min-width: 232px !important; }

.-minw-233 {
  min-width: 233px !important; }

.-minw-234 {
  min-width: 234px !important; }

.-minw-235 {
  min-width: 235px !important; }

.-minw-236 {
  min-width: 236px !important; }

.-minw-237 {
  min-width: 237px !important; }

.-minw-238 {
  min-width: 238px !important; }

.-minw-239 {
  min-width: 239px !important; }

.-minw-240 {
  min-width: 240px !important; }

.-minw-241 {
  min-width: 241px !important; }

.-minw-242 {
  min-width: 242px !important; }

.-minw-243 {
  min-width: 243px !important; }

.-minw-244 {
  min-width: 244px !important; }

.-minw-245 {
  min-width: 245px !important; }

.-minw-246 {
  min-width: 246px !important; }

.-minw-247 {
  min-width: 247px !important; }

.-minw-248 {
  min-width: 248px !important; }

.-minw-249 {
  min-width: 249px !important; }

.-minw-250 {
  min-width: 250px !important; }

.-minw-251 {
  min-width: 251px !important; }

.-minw-252 {
  min-width: 252px !important; }

.-minw-253 {
  min-width: 253px !important; }

.-minw-254 {
  min-width: 254px !important; }

.-minw-255 {
  min-width: 255px !important; }

.-minw-256 {
  min-width: 256px !important; }

.-minw-257 {
  min-width: 257px !important; }

.-minw-258 {
  min-width: 258px !important; }

.-minw-259 {
  min-width: 259px !important; }

.-minw-260 {
  min-width: 260px !important; }

.-minw-261 {
  min-width: 261px !important; }

.-minw-262 {
  min-width: 262px !important; }

.-minw-263 {
  min-width: 263px !important; }

.-minw-264 {
  min-width: 264px !important; }

.-minw-265 {
  min-width: 265px !important; }

.-minw-266 {
  min-width: 266px !important; }

.-minw-267 {
  min-width: 267px !important; }

.-minw-268 {
  min-width: 268px !important; }

.-minw-269 {
  min-width: 269px !important; }

.-minw-270 {
  min-width: 270px !important; }

.-minw-271 {
  min-width: 271px !important; }

.-minw-272 {
  min-width: 272px !important; }

.-minw-273 {
  min-width: 273px !important; }

.-minw-274 {
  min-width: 274px !important; }

.-minw-275 {
  min-width: 275px !important; }

.-minw-276 {
  min-width: 276px !important; }

.-minw-277 {
  min-width: 277px !important; }

.-minw-278 {
  min-width: 278px !important; }

.-minw-279 {
  min-width: 279px !important; }

.-minw-280 {
  min-width: 280px !important; }

.-minw-281 {
  min-width: 281px !important; }

.-minw-282 {
  min-width: 282px !important; }

.-minw-283 {
  min-width: 283px !important; }

.-minw-284 {
  min-width: 284px !important; }

.-minw-285 {
  min-width: 285px !important; }

.-minw-286 {
  min-width: 286px !important; }

.-minw-287 {
  min-width: 287px !important; }

.-minw-288 {
  min-width: 288px !important; }

.-minw-289 {
  min-width: 289px !important; }

.-minw-290 {
  min-width: 290px !important; }

.-minw-291 {
  min-width: 291px !important; }

.-minw-292 {
  min-width: 292px !important; }

.-minw-293 {
  min-width: 293px !important; }

.-minw-294 {
  min-width: 294px !important; }

.-minw-295 {
  min-width: 295px !important; }

.-minw-296 {
  min-width: 296px !important; }

.-minw-297 {
  min-width: 297px !important; }

.-minw-298 {
  min-width: 298px !important; }

.-minw-299 {
  min-width: 299px !important; }

.-minw-300 {
  min-width: 300px !important; }

.-w-1 {
  width: 1px !important; }

.-w-2 {
  width: 2px !important; }

.-w-3 {
  width: 3px !important; }

.-w-4 {
  width: 4px !important; }

.-w-5 {
  width: 5px !important; }

.-w-6 {
  width: 6px !important; }

.-w-7 {
  width: 7px !important; }

.-w-8 {
  width: 8px !important; }

.-w-9 {
  width: 9px !important; }

.-w-10 {
  width: 10px !important; }

.-w-11 {
  width: 11px !important; }

.-w-12 {
  width: 12px !important; }

.-w-13 {
  width: 13px !important; }

.-w-14 {
  width: 14px !important; }

.-w-15 {
  width: 15px !important; }

.-w-16 {
  width: 16px !important; }

.-w-17 {
  width: 17px !important; }

.-w-18 {
  width: 18px !important; }

.-w-19 {
  width: 19px !important; }

.-w-20 {
  width: 20px !important; }

.-w-21 {
  width: 21px !important; }

.-w-22 {
  width: 22px !important; }

.-w-23 {
  width: 23px !important; }

.-w-24 {
  width: 24px !important; }

.-w-25 {
  width: 25px !important; }

.-w-26 {
  width: 26px !important; }

.-w-27 {
  width: 27px !important; }

.-w-28 {
  width: 28px !important; }

.-w-29 {
  width: 29px !important; }

.-w-30 {
  width: 30px !important; }

.-w-31 {
  width: 31px !important; }

.-w-32 {
  width: 32px !important; }

.-w-33 {
  width: 33px !important; }

.-w-34 {
  width: 34px !important; }

.-w-35 {
  width: 35px !important; }

.-w-36 {
  width: 36px !important; }

.-w-37 {
  width: 37px !important; }

.-w-38 {
  width: 38px !important; }

.-w-39 {
  width: 39px !important; }

.-w-40 {
  width: 40px !important; }

.-w-41 {
  width: 41px !important; }

.-w-42 {
  width: 42px !important; }

.-w-43 {
  width: 43px !important; }

.-w-44 {
  width: 44px !important; }

.-w-45 {
  width: 45px !important; }

.-w-46 {
  width: 46px !important; }

.-w-47 {
  width: 47px !important; }

.-w-48 {
  width: 48px !important; }

.-w-49 {
  width: 49px !important; }

.-w-50 {
  width: 50px !important; }

.-w-51 {
  width: 51px !important; }

.-w-52 {
  width: 52px !important; }

.-w-53 {
  width: 53px !important; }

.-w-54 {
  width: 54px !important; }

.-w-55 {
  width: 55px !important; }

.-w-56 {
  width: 56px !important; }

.-w-57 {
  width: 57px !important; }

.-w-58 {
  width: 58px !important; }

.-w-59 {
  width: 59px !important; }

.-w-60 {
  width: 60px !important; }

.-w-61 {
  width: 61px !important; }

.-w-62 {
  width: 62px !important; }

.-w-63 {
  width: 63px !important; }

.-w-64 {
  width: 64px !important; }

.-w-65 {
  width: 65px !important; }

.-w-66 {
  width: 66px !important; }

.-w-67 {
  width: 67px !important; }

.-w-68 {
  width: 68px !important; }

.-w-69 {
  width: 69px !important; }

.-w-70 {
  width: 70px !important; }

.-w-71 {
  width: 71px !important; }

.-w-72 {
  width: 72px !important; }

.-w-73 {
  width: 73px !important; }

.-w-74 {
  width: 74px !important; }

.-w-75 {
  width: 75px !important; }

.-w-76 {
  width: 76px !important; }

.-w-77 {
  width: 77px !important; }

.-w-78 {
  width: 78px !important; }

.-w-79 {
  width: 79px !important; }

.-w-80 {
  width: 80px !important; }

.-w-81 {
  width: 81px !important; }

.-w-82 {
  width: 82px !important; }

.-w-83 {
  width: 83px !important; }

.-w-84 {
  width: 84px !important; }

.-w-85 {
  width: 85px !important; }

.-w-86 {
  width: 86px !important; }

.-w-87 {
  width: 87px !important; }

.-w-88 {
  width: 88px !important; }

.-w-89 {
  width: 89px !important; }

.-w-90 {
  width: 90px !important; }

.-w-91 {
  width: 91px !important; }

.-w-92 {
  width: 92px !important; }

.-w-93 {
  width: 93px !important; }

.-w-94 {
  width: 94px !important; }

.-w-95 {
  width: 95px !important; }

.-w-96 {
  width: 96px !important; }

.-w-97 {
  width: 97px !important; }

.-w-98 {
  width: 98px !important; }

.-w-99 {
  width: 99px !important; }

.-w-100 {
  width: 100px !important; }

.-w-101 {
  width: 101px !important; }

.-w-102 {
  width: 102px !important; }

.-w-103 {
  width: 103px !important; }

.-w-104 {
  width: 104px !important; }

.-w-105 {
  width: 105px !important; }

.-w-106 {
  width: 106px !important; }

.-w-107 {
  width: 107px !important; }

.-w-108 {
  width: 108px !important; }

.-w-109 {
  width: 109px !important; }

.-w-110 {
  width: 110px !important; }

.-w-111 {
  width: 111px !important; }

.-w-112 {
  width: 112px !important; }

.-w-113 {
  width: 113px !important; }

.-w-114 {
  width: 114px !important; }

.-w-115 {
  width: 115px !important; }

.-w-116 {
  width: 116px !important; }

.-w-117 {
  width: 117px !important; }

.-w-118 {
  width: 118px !important; }

.-w-119 {
  width: 119px !important; }

.-w-120 {
  width: 120px !important; }

.-w-121 {
  width: 121px !important; }

.-w-122 {
  width: 122px !important; }

.-w-123 {
  width: 123px !important; }

.-w-124 {
  width: 124px !important; }

.-w-125 {
  width: 125px !important; }

.-w-126 {
  width: 126px !important; }

.-w-127 {
  width: 127px !important; }

.-w-128 {
  width: 128px !important; }

.-w-129 {
  width: 129px !important; }

.-w-130 {
  width: 130px !important; }

.-w-131 {
  width: 131px !important; }

.-w-132 {
  width: 132px !important; }

.-w-133 {
  width: 133px !important; }

.-w-134 {
  width: 134px !important; }

.-w-135 {
  width: 135px !important; }

.-w-136 {
  width: 136px !important; }

.-w-137 {
  width: 137px !important; }

.-w-138 {
  width: 138px !important; }

.-w-139 {
  width: 139px !important; }

.-w-140 {
  width: 140px !important; }

.-w-141 {
  width: 141px !important; }

.-w-142 {
  width: 142px !important; }

.-w-143 {
  width: 143px !important; }

.-w-144 {
  width: 144px !important; }

.-w-145 {
  width: 145px !important; }

.-w-146 {
  width: 146px !important; }

.-w-147 {
  width: 147px !important; }

.-w-148 {
  width: 148px !important; }

.-w-149 {
  width: 149px !important; }

.-w-150 {
  width: 150px !important; }

.-w-151 {
  width: 151px !important; }

.-w-152 {
  width: 152px !important; }

.-w-153 {
  width: 153px !important; }

.-w-154 {
  width: 154px !important; }

.-w-155 {
  width: 155px !important; }

.-w-156 {
  width: 156px !important; }

.-w-157 {
  width: 157px !important; }

.-w-158 {
  width: 158px !important; }

.-w-159 {
  width: 159px !important; }

.-w-160 {
  width: 160px !important; }

.-w-161 {
  width: 161px !important; }

.-w-162 {
  width: 162px !important; }

.-w-163 {
  width: 163px !important; }

.-w-164 {
  width: 164px !important; }

.-w-165 {
  width: 165px !important; }

.-w-166 {
  width: 166px !important; }

.-w-167 {
  width: 167px !important; }

.-w-168 {
  width: 168px !important; }

.-w-169 {
  width: 169px !important; }

.-w-170 {
  width: 170px !important; }

.-w-171 {
  width: 171px !important; }

.-w-172 {
  width: 172px !important; }

.-w-173 {
  width: 173px !important; }

.-w-174 {
  width: 174px !important; }

.-w-175 {
  width: 175px !important; }

.-w-176 {
  width: 176px !important; }

.-w-177 {
  width: 177px !important; }

.-w-178 {
  width: 178px !important; }

.-w-179 {
  width: 179px !important; }

.-w-180 {
  width: 180px !important; }

.-w-181 {
  width: 181px !important; }

.-w-182 {
  width: 182px !important; }

.-w-183 {
  width: 183px !important; }

.-w-184 {
  width: 184px !important; }

.-w-185 {
  width: 185px !important; }

.-w-186 {
  width: 186px !important; }

.-w-187 {
  width: 187px !important; }

.-w-188 {
  width: 188px !important; }

.-w-189 {
  width: 189px !important; }

.-w-190 {
  width: 190px !important; }

.-w-191 {
  width: 191px !important; }

.-w-192 {
  width: 192px !important; }

.-w-193 {
  width: 193px !important; }

.-w-194 {
  width: 194px !important; }

.-w-195 {
  width: 195px !important; }

.-w-196 {
  width: 196px !important; }

.-w-197 {
  width: 197px !important; }

.-w-198 {
  width: 198px !important; }

.-w-199 {
  width: 199px !important; }

.-w-200 {
  width: 200px !important; }

.-w-201 {
  width: 201px !important; }

.-w-202 {
  width: 202px !important; }

.-w-203 {
  width: 203px !important; }

.-w-204 {
  width: 204px !important; }

.-w-205 {
  width: 205px !important; }

.-w-206 {
  width: 206px !important; }

.-w-207 {
  width: 207px !important; }

.-w-208 {
  width: 208px !important; }

.-w-209 {
  width: 209px !important; }

.-w-210 {
  width: 210px !important; }

.-w-211 {
  width: 211px !important; }

.-w-212 {
  width: 212px !important; }

.-w-213 {
  width: 213px !important; }

.-w-214 {
  width: 214px !important; }

.-w-215 {
  width: 215px !important; }

.-w-216 {
  width: 216px !important; }

.-w-217 {
  width: 217px !important; }

.-w-218 {
  width: 218px !important; }

.-w-219 {
  width: 219px !important; }

.-w-220 {
  width: 220px !important; }

.-w-221 {
  width: 221px !important; }

.-w-222 {
  width: 222px !important; }

.-w-223 {
  width: 223px !important; }

.-w-224 {
  width: 224px !important; }

.-w-225 {
  width: 225px !important; }

.-w-226 {
  width: 226px !important; }

.-w-227 {
  width: 227px !important; }

.-w-228 {
  width: 228px !important; }

.-w-229 {
  width: 229px !important; }

.-w-230 {
  width: 230px !important; }

.-w-231 {
  width: 231px !important; }

.-w-232 {
  width: 232px !important; }

.-w-233 {
  width: 233px !important; }

.-w-234 {
  width: 234px !important; }

.-w-235 {
  width: 235px !important; }

.-w-236 {
  width: 236px !important; }

.-w-237 {
  width: 237px !important; }

.-w-238 {
  width: 238px !important; }

.-w-239 {
  width: 239px !important; }

.-w-240 {
  width: 240px !important; }

.-w-241 {
  width: 241px !important; }

.-w-242 {
  width: 242px !important; }

.-w-243 {
  width: 243px !important; }

.-w-244 {
  width: 244px !important; }

.-w-245 {
  width: 245px !important; }

.-w-246 {
  width: 246px !important; }

.-w-247 {
  width: 247px !important; }

.-w-248 {
  width: 248px !important; }

.-w-249 {
  width: 249px !important; }

.-w-250 {
  width: 250px !important; }

.-w-251 {
  width: 251px !important; }

.-w-252 {
  width: 252px !important; }

.-w-253 {
  width: 253px !important; }

.-w-254 {
  width: 254px !important; }

.-w-255 {
  width: 255px !important; }

.-w-256 {
  width: 256px !important; }

.-w-257 {
  width: 257px !important; }

.-w-258 {
  width: 258px !important; }

.-w-259 {
  width: 259px !important; }

.-w-260 {
  width: 260px !important; }

.-w-261 {
  width: 261px !important; }

.-w-262 {
  width: 262px !important; }

.-w-263 {
  width: 263px !important; }

.-w-264 {
  width: 264px !important; }

.-w-265 {
  width: 265px !important; }

.-w-266 {
  width: 266px !important; }

.-w-267 {
  width: 267px !important; }

.-w-268 {
  width: 268px !important; }

.-w-269 {
  width: 269px !important; }

.-w-270 {
  width: 270px !important; }

.-w-271 {
  width: 271px !important; }

.-w-272 {
  width: 272px !important; }

.-w-273 {
  width: 273px !important; }

.-w-274 {
  width: 274px !important; }

.-w-275 {
  width: 275px !important; }

.-w-276 {
  width: 276px !important; }

.-w-277 {
  width: 277px !important; }

.-w-278 {
  width: 278px !important; }

.-w-279 {
  width: 279px !important; }

.-w-280 {
  width: 280px !important; }

.-w-281 {
  width: 281px !important; }

.-w-282 {
  width: 282px !important; }

.-w-283 {
  width: 283px !important; }

.-w-284 {
  width: 284px !important; }

.-w-285 {
  width: 285px !important; }

.-w-286 {
  width: 286px !important; }

.-w-287 {
  width: 287px !important; }

.-w-288 {
  width: 288px !important; }

.-w-289 {
  width: 289px !important; }

.-w-290 {
  width: 290px !important; }

.-w-291 {
  width: 291px !important; }

.-w-292 {
  width: 292px !important; }

.-w-293 {
  width: 293px !important; }

.-w-294 {
  width: 294px !important; }

.-w-295 {
  width: 295px !important; }

.-w-296 {
  width: 296px !important; }

.-w-297 {
  width: 297px !important; }

.-w-298 {
  width: 298px !important; }

.-w-299 {
  width: 299px !important; }

.-w-300 {
  width: 300px !important; }

/*------------------------------------------------------------------------------
Display Adjustments
-------------------------------------------------------------------------------- */
.-d-block {
  display: block !important; }

.-d-flex {
  display: flex !important; }

.-d-flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.-d-flex-col {
  display: flex !important;
  flex-direction: column !important; }

.-d-flex-col-4 {
  display: flex !important;
  flex-direction: column !important;
  gap: 4px !important; }

.-d-flex-col-8 {
  display: flex !important;
  flex-direction: column !important;
  gap: 8px !important; }

.-d-flex-col-12 {
  display: flex !important;
  flex-direction: column !important;
  gap: 12px !important; }

.-d-flex-col-16 {
  display: flex !important;
  flex-direction: column !important;
  gap: 16px !important; }

.-d-flex-col-24 {
  display: flex !important;
  flex-direction: column !important;
  gap: 24px !important; }

.-d-flex-col-32 {
  display: flex !important;
  flex-direction: column !important;
  gap: 32px !important; }

.-d-flex-col-40 {
  display: flex !important;
  flex-direction: column !important;
  gap: 40px !important; }

.-d-flex {
  display: flex !important; }

.-d-flex-8 {
  display: flex !important;
  gap: 8px !important; }

.-d-flex-12 {
  display: flex !important;
  gap: 12px !important; }

.-d-flex-16 {
  display: flex !important;
  gap: 16px !important; }

.-d-flex-24 {
  display: flex !important;
  gap: 24px !important; }

.-d-flex-40 {
  display: flex !important;
  gap: 40px !important; }

.-d-flex-between {
  display: flex !important;
  justify-content: space-between; }

.-d-none {
  display: none !important;
  margin: 0 !important; }

.-hidden {
  display: none !important; }

@media (max-width: 575px) {
  .-hidden-xs-max {
    display: none !important; } }

@media (min-width: 576px) {
  .-hidden-sm {
    display: none !important; } }

@media (max-width: 767px) {
  .-hidden-sm-max {
    display: none !important; } }

@media (min-width: 768px) {
  .-hidden-md {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .-hidden-md-only {
    display: none !important; } }

@media (max-width: 991px) {
  .-hidden-md-max {
    display: none !important; } }

@media (min-width: 992px) {
  .-hidden-lg {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1223px) {
  .-hidden-lg-only {
    display: none !important; } }

@media (max-width: 1223px) {
  .-hidden-lg-max {
    display: none !important; } }

@media (min-width: 1224px) {
  .-hidden-xl {
    display: none !important; } }

[class*="-visible-"] {
  display: none; }

@media (max-width: 575px) {
  .-visible-xs-max {
    display: initial !important; } }

@media (min-width: 576px) {
  .-visible-sm {
    display: initial !important; } }

@media (max-width: 767px) {
  .-visible-sm-max {
    display: initial !important; } }

@media (min-width: 768px) {
  .-visible-md {
    display: initial !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .-visible-md-only {
    display: initial !important; } }

@media (max-width: 991px) {
  .-visible-md-max {
    display: initial !important; } }

@media (min-width: 992px) {
  .-visible-lg {
    display: initial !important; } }

@media (min-width: 992px) and (max-width: 1223px) {
  .-visible-lg-only {
    display: initial !important; } }

@media (max-width: 1223px) {
  .-visible-lg-max {
    display: initial !important; } }

@media (min-width: 1224px) {
  .-visible-xl {
    display: initial !important; } }

/*------------------------------------------------------------------------------
Flex 
-------------------------------------------------------------------------------- */
.-fb-1 {
  flex-basis: 1px !important; }

.-fb-2 {
  flex-basis: 2px !important; }

.-fb-3 {
  flex-basis: 3px !important; }

.-fb-4 {
  flex-basis: 4px !important; }

.-fb-5 {
  flex-basis: 5px !important; }

.-fb-6 {
  flex-basis: 6px !important; }

.-fb-7 {
  flex-basis: 7px !important; }

.-fb-8 {
  flex-basis: 8px !important; }

.-fb-9 {
  flex-basis: 9px !important; }

.-fb-10 {
  flex-basis: 10px !important; }

.-fb-11 {
  flex-basis: 11px !important; }

.-fb-12 {
  flex-basis: 12px !important; }

.-fb-13 {
  flex-basis: 13px !important; }

.-fb-14 {
  flex-basis: 14px !important; }

.-fb-15 {
  flex-basis: 15px !important; }

.-fb-16 {
  flex-basis: 16px !important; }

.-fb-17 {
  flex-basis: 17px !important; }

.-fb-18 {
  flex-basis: 18px !important; }

.-fb-19 {
  flex-basis: 19px !important; }

.-fb-20 {
  flex-basis: 20px !important; }

.-fb-21 {
  flex-basis: 21px !important; }

.-fb-22 {
  flex-basis: 22px !important; }

.-fb-23 {
  flex-basis: 23px !important; }

.-fb-24 {
  flex-basis: 24px !important; }

.-fb-25 {
  flex-basis: 25px !important; }

.-fb-26 {
  flex-basis: 26px !important; }

.-fb-27 {
  flex-basis: 27px !important; }

.-fb-28 {
  flex-basis: 28px !important; }

.-fb-29 {
  flex-basis: 29px !important; }

.-fb-30 {
  flex-basis: 30px !important; }

.-fb-31 {
  flex-basis: 31px !important; }

.-fb-32 {
  flex-basis: 32px !important; }

.-fb-33 {
  flex-basis: 33px !important; }

.-fb-34 {
  flex-basis: 34px !important; }

.-fb-35 {
  flex-basis: 35px !important; }

.-fb-36 {
  flex-basis: 36px !important; }

.-fb-37 {
  flex-basis: 37px !important; }

.-fb-38 {
  flex-basis: 38px !important; }

.-fb-39 {
  flex-basis: 39px !important; }

.-fb-40 {
  flex-basis: 40px !important; }

.-fb-41 {
  flex-basis: 41px !important; }

.-fb-42 {
  flex-basis: 42px !important; }

.-fb-43 {
  flex-basis: 43px !important; }

.-fb-44 {
  flex-basis: 44px !important; }

.-fb-45 {
  flex-basis: 45px !important; }

.-fb-46 {
  flex-basis: 46px !important; }

.-fb-47 {
  flex-basis: 47px !important; }

.-fb-48 {
  flex-basis: 48px !important; }

.-fb-49 {
  flex-basis: 49px !important; }

.-fb-50 {
  flex-basis: 50px !important; }

.-fb-51 {
  flex-basis: 51px !important; }

.-fb-52 {
  flex-basis: 52px !important; }

.-fb-53 {
  flex-basis: 53px !important; }

.-fb-54 {
  flex-basis: 54px !important; }

.-fb-55 {
  flex-basis: 55px !important; }

.-fb-56 {
  flex-basis: 56px !important; }

.-fb-57 {
  flex-basis: 57px !important; }

.-fb-58 {
  flex-basis: 58px !important; }

.-fb-59 {
  flex-basis: 59px !important; }

.-fb-60 {
  flex-basis: 60px !important; }

.-fb-61 {
  flex-basis: 61px !important; }

.-fb-62 {
  flex-basis: 62px !important; }

.-fb-63 {
  flex-basis: 63px !important; }

.-fb-64 {
  flex-basis: 64px !important; }

.-fb-65 {
  flex-basis: 65px !important; }

.-fb-66 {
  flex-basis: 66px !important; }

.-fb-67 {
  flex-basis: 67px !important; }

.-fb-68 {
  flex-basis: 68px !important; }

.-fb-69 {
  flex-basis: 69px !important; }

.-fb-70 {
  flex-basis: 70px !important; }

.-fb-71 {
  flex-basis: 71px !important; }

.-fb-72 {
  flex-basis: 72px !important; }

.-fb-73 {
  flex-basis: 73px !important; }

.-fb-74 {
  flex-basis: 74px !important; }

.-fb-75 {
  flex-basis: 75px !important; }

.-fb-76 {
  flex-basis: 76px !important; }

.-fb-77 {
  flex-basis: 77px !important; }

.-fb-78 {
  flex-basis: 78px !important; }

.-fb-79 {
  flex-basis: 79px !important; }

.-fb-80 {
  flex-basis: 80px !important; }

.-fb-81 {
  flex-basis: 81px !important; }

.-fb-82 {
  flex-basis: 82px !important; }

.-fb-83 {
  flex-basis: 83px !important; }

.-fb-84 {
  flex-basis: 84px !important; }

.-fb-85 {
  flex-basis: 85px !important; }

.-fb-86 {
  flex-basis: 86px !important; }

.-fb-87 {
  flex-basis: 87px !important; }

.-fb-88 {
  flex-basis: 88px !important; }

.-fb-89 {
  flex-basis: 89px !important; }

.-fb-90 {
  flex-basis: 90px !important; }

.-fb-91 {
  flex-basis: 91px !important; }

.-fb-92 {
  flex-basis: 92px !important; }

.-fb-93 {
  flex-basis: 93px !important; }

.-fb-94 {
  flex-basis: 94px !important; }

.-fb-95 {
  flex-basis: 95px !important; }

.-fb-96 {
  flex-basis: 96px !important; }

.-fb-97 {
  flex-basis: 97px !important; }

.-fb-98 {
  flex-basis: 98px !important; }

.-fb-99 {
  flex-basis: 99px !important; }

.-fb-100 {
  flex-basis: 100px !important; }

.-fb-101 {
  flex-basis: 101px !important; }

.-fb-102 {
  flex-basis: 102px !important; }

.-fb-103 {
  flex-basis: 103px !important; }

.-fb-104 {
  flex-basis: 104px !important; }

.-fb-105 {
  flex-basis: 105px !important; }

.-fb-106 {
  flex-basis: 106px !important; }

.-fb-107 {
  flex-basis: 107px !important; }

.-fb-108 {
  flex-basis: 108px !important; }

.-fb-109 {
  flex-basis: 109px !important; }

.-fb-110 {
  flex-basis: 110px !important; }

.-fb-111 {
  flex-basis: 111px !important; }

.-fb-112 {
  flex-basis: 112px !important; }

.-fb-113 {
  flex-basis: 113px !important; }

.-fb-114 {
  flex-basis: 114px !important; }

.-fb-115 {
  flex-basis: 115px !important; }

.-fb-116 {
  flex-basis: 116px !important; }

.-fb-117 {
  flex-basis: 117px !important; }

.-fb-118 {
  flex-basis: 118px !important; }

.-fb-119 {
  flex-basis: 119px !important; }

.-fb-120 {
  flex-basis: 120px !important; }

.-fb-121 {
  flex-basis: 121px !important; }

.-fb-122 {
  flex-basis: 122px !important; }

.-fb-123 {
  flex-basis: 123px !important; }

.-fb-124 {
  flex-basis: 124px !important; }

.-fb-125 {
  flex-basis: 125px !important; }

.-fb-126 {
  flex-basis: 126px !important; }

.-fb-127 {
  flex-basis: 127px !important; }

.-fb-128 {
  flex-basis: 128px !important; }

.-fb-129 {
  flex-basis: 129px !important; }

.-fb-130 {
  flex-basis: 130px !important; }

.-fb-131 {
  flex-basis: 131px !important; }

.-fb-132 {
  flex-basis: 132px !important; }

.-fb-133 {
  flex-basis: 133px !important; }

.-fb-134 {
  flex-basis: 134px !important; }

.-fb-135 {
  flex-basis: 135px !important; }

.-fb-136 {
  flex-basis: 136px !important; }

.-fb-137 {
  flex-basis: 137px !important; }

.-fb-138 {
  flex-basis: 138px !important; }

.-fb-139 {
  flex-basis: 139px !important; }

.-fb-140 {
  flex-basis: 140px !important; }

.-fb-141 {
  flex-basis: 141px !important; }

.-fb-142 {
  flex-basis: 142px !important; }

.-fb-143 {
  flex-basis: 143px !important; }

.-fb-144 {
  flex-basis: 144px !important; }

.-fb-145 {
  flex-basis: 145px !important; }

.-fb-146 {
  flex-basis: 146px !important; }

.-fb-147 {
  flex-basis: 147px !important; }

.-fb-148 {
  flex-basis: 148px !important; }

.-fb-149 {
  flex-basis: 149px !important; }

.-fb-150 {
  flex-basis: 150px !important; }

.-fb-151 {
  flex-basis: 151px !important; }

.-fb-152 {
  flex-basis: 152px !important; }

.-fb-153 {
  flex-basis: 153px !important; }

.-fb-154 {
  flex-basis: 154px !important; }

.-fb-155 {
  flex-basis: 155px !important; }

.-fb-156 {
  flex-basis: 156px !important; }

.-fb-157 {
  flex-basis: 157px !important; }

.-fb-158 {
  flex-basis: 158px !important; }

.-fb-159 {
  flex-basis: 159px !important; }

.-fb-160 {
  flex-basis: 160px !important; }

.-fb-161 {
  flex-basis: 161px !important; }

.-fb-162 {
  flex-basis: 162px !important; }

.-fb-163 {
  flex-basis: 163px !important; }

.-fb-164 {
  flex-basis: 164px !important; }

.-fb-165 {
  flex-basis: 165px !important; }

.-fb-166 {
  flex-basis: 166px !important; }

.-fb-167 {
  flex-basis: 167px !important; }

.-fb-168 {
  flex-basis: 168px !important; }

.-fb-169 {
  flex-basis: 169px !important; }

.-fb-170 {
  flex-basis: 170px !important; }

.-fb-171 {
  flex-basis: 171px !important; }

.-fb-172 {
  flex-basis: 172px !important; }

.-fb-173 {
  flex-basis: 173px !important; }

.-fb-174 {
  flex-basis: 174px !important; }

.-fb-175 {
  flex-basis: 175px !important; }

.-fb-176 {
  flex-basis: 176px !important; }

.-fb-177 {
  flex-basis: 177px !important; }

.-fb-178 {
  flex-basis: 178px !important; }

.-fb-179 {
  flex-basis: 179px !important; }

.-fb-180 {
  flex-basis: 180px !important; }

.-fb-181 {
  flex-basis: 181px !important; }

.-fb-182 {
  flex-basis: 182px !important; }

.-fb-183 {
  flex-basis: 183px !important; }

.-fb-184 {
  flex-basis: 184px !important; }

.-fb-185 {
  flex-basis: 185px !important; }

.-fb-186 {
  flex-basis: 186px !important; }

.-fb-187 {
  flex-basis: 187px !important; }

.-fb-188 {
  flex-basis: 188px !important; }

.-fb-189 {
  flex-basis: 189px !important; }

.-fb-190 {
  flex-basis: 190px !important; }

.-fb-191 {
  flex-basis: 191px !important; }

.-fb-192 {
  flex-basis: 192px !important; }

.-fb-193 {
  flex-basis: 193px !important; }

.-fb-194 {
  flex-basis: 194px !important; }

.-fb-195 {
  flex-basis: 195px !important; }

.-fb-196 {
  flex-basis: 196px !important; }

.-fb-197 {
  flex-basis: 197px !important; }

.-fb-198 {
  flex-basis: 198px !important; }

.-fb-199 {
  flex-basis: 199px !important; }

.-fb-200 {
  flex-basis: 200px !important; }

.-fb-201 {
  flex-basis: 201px !important; }

.-fb-202 {
  flex-basis: 202px !important; }

.-fb-203 {
  flex-basis: 203px !important; }

.-fb-204 {
  flex-basis: 204px !important; }

.-fb-205 {
  flex-basis: 205px !important; }

.-fb-206 {
  flex-basis: 206px !important; }

.-fb-207 {
  flex-basis: 207px !important; }

.-fb-208 {
  flex-basis: 208px !important; }

.-fb-209 {
  flex-basis: 209px !important; }

.-fb-210 {
  flex-basis: 210px !important; }

.-fb-211 {
  flex-basis: 211px !important; }

.-fb-212 {
  flex-basis: 212px !important; }

.-fb-213 {
  flex-basis: 213px !important; }

.-fb-214 {
  flex-basis: 214px !important; }

.-fb-215 {
  flex-basis: 215px !important; }

.-fb-216 {
  flex-basis: 216px !important; }

.-fb-217 {
  flex-basis: 217px !important; }

.-fb-218 {
  flex-basis: 218px !important; }

.-fb-219 {
  flex-basis: 219px !important; }

.-fb-220 {
  flex-basis: 220px !important; }

.-fb-221 {
  flex-basis: 221px !important; }

.-fb-222 {
  flex-basis: 222px !important; }

.-fb-223 {
  flex-basis: 223px !important; }

.-fb-224 {
  flex-basis: 224px !important; }

.-fb-225 {
  flex-basis: 225px !important; }

.-fb-226 {
  flex-basis: 226px !important; }

.-fb-227 {
  flex-basis: 227px !important; }

.-fb-228 {
  flex-basis: 228px !important; }

.-fb-229 {
  flex-basis: 229px !important; }

.-fb-230 {
  flex-basis: 230px !important; }

.-fb-231 {
  flex-basis: 231px !important; }

.-fb-232 {
  flex-basis: 232px !important; }

.-fb-233 {
  flex-basis: 233px !important; }

.-fb-234 {
  flex-basis: 234px !important; }

.-fb-235 {
  flex-basis: 235px !important; }

.-fb-236 {
  flex-basis: 236px !important; }

.-fb-237 {
  flex-basis: 237px !important; }

.-fb-238 {
  flex-basis: 238px !important; }

.-fb-239 {
  flex-basis: 239px !important; }

.-fb-240 {
  flex-basis: 240px !important; }

.-fb-241 {
  flex-basis: 241px !important; }

.-fb-242 {
  flex-basis: 242px !important; }

.-fb-243 {
  flex-basis: 243px !important; }

.-fb-244 {
  flex-basis: 244px !important; }

.-fb-245 {
  flex-basis: 245px !important; }

.-fb-246 {
  flex-basis: 246px !important; }

.-fb-247 {
  flex-basis: 247px !important; }

.-fb-248 {
  flex-basis: 248px !important; }

.-fb-249 {
  flex-basis: 249px !important; }

.-fb-250 {
  flex-basis: 250px !important; }

.-fb-251 {
  flex-basis: 251px !important; }

.-fb-252 {
  flex-basis: 252px !important; }

.-fb-253 {
  flex-basis: 253px !important; }

.-fb-254 {
  flex-basis: 254px !important; }

.-fb-255 {
  flex-basis: 255px !important; }

.-fb-256 {
  flex-basis: 256px !important; }

.-fb-257 {
  flex-basis: 257px !important; }

.-fb-258 {
  flex-basis: 258px !important; }

.-fb-259 {
  flex-basis: 259px !important; }

.-fb-260 {
  flex-basis: 260px !important; }

.-fb-261 {
  flex-basis: 261px !important; }

.-fb-262 {
  flex-basis: 262px !important; }

.-fb-263 {
  flex-basis: 263px !important; }

.-fb-264 {
  flex-basis: 264px !important; }

.-fb-265 {
  flex-basis: 265px !important; }

.-fb-266 {
  flex-basis: 266px !important; }

.-fb-267 {
  flex-basis: 267px !important; }

.-fb-268 {
  flex-basis: 268px !important; }

.-fb-269 {
  flex-basis: 269px !important; }

.-fb-270 {
  flex-basis: 270px !important; }

.-fb-271 {
  flex-basis: 271px !important; }

.-fb-272 {
  flex-basis: 272px !important; }

.-fb-273 {
  flex-basis: 273px !important; }

.-fb-274 {
  flex-basis: 274px !important; }

.-fb-275 {
  flex-basis: 275px !important; }

.-fb-276 {
  flex-basis: 276px !important; }

.-fb-277 {
  flex-basis: 277px !important; }

.-fb-278 {
  flex-basis: 278px !important; }

.-fb-279 {
  flex-basis: 279px !important; }

.-fb-280 {
  flex-basis: 280px !important; }

.-fb-281 {
  flex-basis: 281px !important; }

.-fb-282 {
  flex-basis: 282px !important; }

.-fb-283 {
  flex-basis: 283px !important; }

.-fb-284 {
  flex-basis: 284px !important; }

.-fb-285 {
  flex-basis: 285px !important; }

.-fb-286 {
  flex-basis: 286px !important; }

.-fb-287 {
  flex-basis: 287px !important; }

.-fb-288 {
  flex-basis: 288px !important; }

.-fb-289 {
  flex-basis: 289px !important; }

.-fb-290 {
  flex-basis: 290px !important; }

.-fb-291 {
  flex-basis: 291px !important; }

.-fb-292 {
  flex-basis: 292px !important; }

.-fb-293 {
  flex-basis: 293px !important; }

.-fb-294 {
  flex-basis: 294px !important; }

.-fb-295 {
  flex-basis: 295px !important; }

.-fb-296 {
  flex-basis: 296px !important; }

.-fb-297 {
  flex-basis: 297px !important; }

.-fb-298 {
  flex-basis: 298px !important; }

.-fb-299 {
  flex-basis: 299px !important; }

.-fb-300 {
  flex-basis: 300px !important; }

.-fb-301 {
  flex-basis: 301px !important; }

.-fb-302 {
  flex-basis: 302px !important; }

.-fb-303 {
  flex-basis: 303px !important; }

.-fb-304 {
  flex-basis: 304px !important; }

.-fb-305 {
  flex-basis: 305px !important; }

.-fb-306 {
  flex-basis: 306px !important; }

.-fb-307 {
  flex-basis: 307px !important; }

.-fb-308 {
  flex-basis: 308px !important; }

.-fb-309 {
  flex-basis: 309px !important; }

.-fb-310 {
  flex-basis: 310px !important; }

.-fb-311 {
  flex-basis: 311px !important; }

.-fb-312 {
  flex-basis: 312px !important; }

.-fb-313 {
  flex-basis: 313px !important; }

.-fb-314 {
  flex-basis: 314px !important; }

.-fb-315 {
  flex-basis: 315px !important; }

.-fb-316 {
  flex-basis: 316px !important; }

.-fb-317 {
  flex-basis: 317px !important; }

.-fb-318 {
  flex-basis: 318px !important; }

.-fb-319 {
  flex-basis: 319px !important; }

.-fb-320 {
  flex-basis: 320px !important; }

.-fb-321 {
  flex-basis: 321px !important; }

.-fb-322 {
  flex-basis: 322px !important; }

.-fb-323 {
  flex-basis: 323px !important; }

.-fb-324 {
  flex-basis: 324px !important; }

.-fb-325 {
  flex-basis: 325px !important; }

.-fb-326 {
  flex-basis: 326px !important; }

.-fb-327 {
  flex-basis: 327px !important; }

.-fb-328 {
  flex-basis: 328px !important; }

.-fb-329 {
  flex-basis: 329px !important; }

.-fb-330 {
  flex-basis: 330px !important; }

.-fb-331 {
  flex-basis: 331px !important; }

.-fb-332 {
  flex-basis: 332px !important; }

.-fb-333 {
  flex-basis: 333px !important; }

.-fb-334 {
  flex-basis: 334px !important; }

.-fb-335 {
  flex-basis: 335px !important; }

.-fb-336 {
  flex-basis: 336px !important; }

.-fb-337 {
  flex-basis: 337px !important; }

.-fb-338 {
  flex-basis: 338px !important; }

.-fb-339 {
  flex-basis: 339px !important; }

.-fb-340 {
  flex-basis: 340px !important; }

.-fb-341 {
  flex-basis: 341px !important; }

.-fb-342 {
  flex-basis: 342px !important; }

.-fb-343 {
  flex-basis: 343px !important; }

.-fb-344 {
  flex-basis: 344px !important; }

.-fb-345 {
  flex-basis: 345px !important; }

.-fb-346 {
  flex-basis: 346px !important; }

.-fb-347 {
  flex-basis: 347px !important; }

.-fb-348 {
  flex-basis: 348px !important; }

.-fb-349 {
  flex-basis: 349px !important; }

.-fb-350 {
  flex-basis: 350px !important; }

.-fb-351 {
  flex-basis: 351px !important; }

.-fb-352 {
  flex-basis: 352px !important; }

.-fb-353 {
  flex-basis: 353px !important; }

.-fb-354 {
  flex-basis: 354px !important; }

.-fb-355 {
  flex-basis: 355px !important; }

.-fb-356 {
  flex-basis: 356px !important; }

.-fb-357 {
  flex-basis: 357px !important; }

.-fb-358 {
  flex-basis: 358px !important; }

.-fb-359 {
  flex-basis: 359px !important; }

.-fb-360 {
  flex-basis: 360px !important; }

.-fb-361 {
  flex-basis: 361px !important; }

.-fb-362 {
  flex-basis: 362px !important; }

.-fb-363 {
  flex-basis: 363px !important; }

.-fb-364 {
  flex-basis: 364px !important; }

.-fb-365 {
  flex-basis: 365px !important; }

.-fb-366 {
  flex-basis: 366px !important; }

.-fb-367 {
  flex-basis: 367px !important; }

.-fb-368 {
  flex-basis: 368px !important; }

.-fb-369 {
  flex-basis: 369px !important; }

.-fb-370 {
  flex-basis: 370px !important; }

.-fb-371 {
  flex-basis: 371px !important; }

.-fb-372 {
  flex-basis: 372px !important; }

.-fb-373 {
  flex-basis: 373px !important; }

.-fb-374 {
  flex-basis: 374px !important; }

.-fb-375 {
  flex-basis: 375px !important; }

.-fb-376 {
  flex-basis: 376px !important; }

.-fb-377 {
  flex-basis: 377px !important; }

.-fb-378 {
  flex-basis: 378px !important; }

.-fb-379 {
  flex-basis: 379px !important; }

.-fb-380 {
  flex-basis: 380px !important; }

.-fb-381 {
  flex-basis: 381px !important; }

.-fb-382 {
  flex-basis: 382px !important; }

.-fb-383 {
  flex-basis: 383px !important; }

.-fb-384 {
  flex-basis: 384px !important; }

.-fb-385 {
  flex-basis: 385px !important; }

.-fb-386 {
  flex-basis: 386px !important; }

.-fb-387 {
  flex-basis: 387px !important; }

.-fb-388 {
  flex-basis: 388px !important; }

.-fb-389 {
  flex-basis: 389px !important; }

.-fb-390 {
  flex-basis: 390px !important; }

.-fb-391 {
  flex-basis: 391px !important; }

.-fb-392 {
  flex-basis: 392px !important; }

.-fb-393 {
  flex-basis: 393px !important; }

.-fb-394 {
  flex-basis: 394px !important; }

.-fb-395 {
  flex-basis: 395px !important; }

.-fb-396 {
  flex-basis: 396px !important; }

.-fb-397 {
  flex-basis: 397px !important; }

.-fb-398 {
  flex-basis: 398px !important; }

.-fb-399 {
  flex-basis: 399px !important; }

.-fb-400 {
  flex-basis: 400px !important; }

.-fb-401 {
  flex-basis: 401px !important; }

.-fb-402 {
  flex-basis: 402px !important; }

.-fb-403 {
  flex-basis: 403px !important; }

.-fb-404 {
  flex-basis: 404px !important; }

.-fb-405 {
  flex-basis: 405px !important; }

.-fb-406 {
  flex-basis: 406px !important; }

.-fb-407 {
  flex-basis: 407px !important; }

.-fb-408 {
  flex-basis: 408px !important; }

.-fb-409 {
  flex-basis: 409px !important; }

.-fb-410 {
  flex-basis: 410px !important; }

.-fb-411 {
  flex-basis: 411px !important; }

.-fb-412 {
  flex-basis: 412px !important; }

.-fb-413 {
  flex-basis: 413px !important; }

.-fb-414 {
  flex-basis: 414px !important; }

.-fb-415 {
  flex-basis: 415px !important; }

.-fb-416 {
  flex-basis: 416px !important; }

.-fb-417 {
  flex-basis: 417px !important; }

.-fb-418 {
  flex-basis: 418px !important; }

.-fb-419 {
  flex-basis: 419px !important; }

.-fb-420 {
  flex-basis: 420px !important; }

.-fb-421 {
  flex-basis: 421px !important; }

.-fb-422 {
  flex-basis: 422px !important; }

.-fb-423 {
  flex-basis: 423px !important; }

.-fb-424 {
  flex-basis: 424px !important; }

.-fb-425 {
  flex-basis: 425px !important; }

.-fb-426 {
  flex-basis: 426px !important; }

.-fb-427 {
  flex-basis: 427px !important; }

.-fb-428 {
  flex-basis: 428px !important; }

.-fb-429 {
  flex-basis: 429px !important; }

.-fb-430 {
  flex-basis: 430px !important; }

.-fb-431 {
  flex-basis: 431px !important; }

.-fb-432 {
  flex-basis: 432px !important; }

.-fb-433 {
  flex-basis: 433px !important; }

.-fb-434 {
  flex-basis: 434px !important; }

.-fb-435 {
  flex-basis: 435px !important; }

.-fb-436 {
  flex-basis: 436px !important; }

.-fb-437 {
  flex-basis: 437px !important; }

.-fb-438 {
  flex-basis: 438px !important; }

.-fb-439 {
  flex-basis: 439px !important; }

.-fb-440 {
  flex-basis: 440px !important; }

.-fb-441 {
  flex-basis: 441px !important; }

.-fb-442 {
  flex-basis: 442px !important; }

.-fb-443 {
  flex-basis: 443px !important; }

.-fb-444 {
  flex-basis: 444px !important; }

.-fb-445 {
  flex-basis: 445px !important; }

.-fb-446 {
  flex-basis: 446px !important; }

.-fb-447 {
  flex-basis: 447px !important; }

.-fb-448 {
  flex-basis: 448px !important; }

.-fb-449 {
  flex-basis: 449px !important; }

.-fb-450 {
  flex-basis: 450px !important; }

.-fg-0 {
  flex-grow: 0 !important; }

.-fg-1 {
  flex-grow: 1 !important; }

.-fs-0 {
  flex-shrink: 0 !important; }

.-gap-1 {
  gap: 1px !important; }

.-gap-2 {
  gap: 2px !important; }

.-gap-3 {
  gap: 3px !important; }

.-gap-4 {
  gap: 4px !important; }

.-gap-5 {
  gap: 5px !important; }

.-gap-6 {
  gap: 6px !important; }

.-gap-7 {
  gap: 7px !important; }

.-gap-8 {
  gap: 8px !important; }

.-gap-9 {
  gap: 9px !important; }

.-gap-10 {
  gap: 10px !important; }

.-gap-11 {
  gap: 11px !important; }

.-gap-12 {
  gap: 12px !important; }

.-gap-13 {
  gap: 13px !important; }

.-gap-14 {
  gap: 14px !important; }

.-gap-15 {
  gap: 15px !important; }

.-gap-16 {
  gap: 16px !important; }

.-gap-17 {
  gap: 17px !important; }

.-gap-18 {
  gap: 18px !important; }

.-gap-19 {
  gap: 19px !important; }

.-gap-20 {
  gap: 20px !important; }

.-gap-21 {
  gap: 21px !important; }

.-gap-22 {
  gap: 22px !important; }

.-gap-23 {
  gap: 23px !important; }

.-gap-24 {
  gap: 24px !important; }

.-r-gap-24 {
  row-gap: 24px; }

.-r-gap-32 {
  row-gap: 24px; }

.-r-gap-40 {
  row-gap: 40px; }

@media (max-width: 575px) {
  .-r-gap-24-xs-max {
    row-gap: 24px; }
  .-r-gap-32-xs-max {
    row-gap: 32px; }
  .-r-gap-40-xs-max {
    row-gap: 40px; } }

@media (max-width: 767px) {
  .-r-gap-24-sm-max {
    row-gap: 24px; }
  .-r-gap-32-sm-max {
    row-gap: 32px; }
  .-r-gap-40-sm-max {
    row-gap: 40px; } }

.-jc-between {
  justify-content: space-between; }

.-jc-center {
  justify-content: center; }

.-jc-start {
  justify-content: start !important; }

.-ai-center {
  align-items: center; }

.-as-start {
  align-self: start !important; }

.-as-center {
  align-self: center !important; }

.-fw-wrap {
  flex-wrap: wrap; }

/*------------------------------------------------------------------------------
Word Break
-------------------------------------------------------------------------------- */
.-wb-break-all {
  word-break: break-all !important; }

/*------------------------------------------------------------------------------
Float
-------------------------------------------------------------------------------- */
.-float-left {
  float: left !important; }

.-float-right {
  float: right !important; }

.row.--gap-16 {
  margin-right: -8px;
  margin-left: -8px; }

.row.--gap-16 > [class^="col"] {
  padding-right: 8px;
  padding-left: 8px; }

@media (max-width: 575px) {
  .container {
    padding-right: 16px;
    padding-left: 16px; } }

/*******************************************************************************************
* Objects
********************************************************************************************/
/*------------------------------------------------------------------------------
Card
-- White, rounded box with a border
-------------------------------------------------------------------------------- */
.o-card, .c-blog__entry {
  background-color: #fff;
  border: solid 1px #D7D7D7;
  border-radius: 4px; }
  .o-card .--no-border, .c-blog__entry .--no-border {
    border: 0; }
  .o-card.--blue-bg-hover:hover, .--blue-bg-hover.c-blog__entry:hover {
    background-color: #0062FF;
    border-color: #0062FF; }
    .o-card.--blue-bg-hover:hover .c-point__icon, .--blue-bg-hover.c-blog__entry:hover .c-point__icon, .o-card.--blue-bg-hover:hover .c-point__head, .--blue-bg-hover.c-blog__entry:hover .c-point__head {
      color: #FFF; }
  .o-card.--hover-shadow:hover, .c-blog__entry:hover {
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.15); }

/*******************************************************************************************
* Components
********************************************************************************************/
.c-nav {
  min-height: 57px;
  border-bottom: solid 1px #D7D7D7;
  background-color: #FFF;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center; }
  @media (max-width: 1223px) {
    .c-nav {
      align-items: inherit; } }
  .c-nav__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media (max-width: 1223px) {
      .c-nav__wrap {
        padding-top: 12px;
        padding-bottom: 12px; } }
  .c-nav__logo {
    width: 90px;
    height: 31px;
    display: block; }
  .c-nav__menu {
    font-size: 1.6em;
    cursor: pointer;
    padding: 8px 0 8px 8px; }
    @media (min-width: 1224px) {
      .c-nav__menu {
        display: none; } }
  .c-nav__links {
    display: flex;
    font-size: 1.4em;
    gap: 32px;
    color: #888888; }
    .c-nav__links a:hover {
      color: #000; }
    @media (max-width: 1223px) {
      .c-nav__links {
        display: none;
        width: 100%;
        flex-direction: column;
        text-align: center;
        margin-top: 40px;
        padding-bottom: 16px; } }
    @media (min-width: 1224px) {
      .c-nav__links {
        display: flex !important; } }
    .c-nav__links .-active {
      color: #000; }

.c-btn {
  display: inline-flex;
  height: 42px;
  align-items: center;
  gap: 6px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  padding: 0 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  background-color: #0062FF;
  color: #FFF; }
  .c-btn:hover {
    background-color: #0054db; }
  .c-btn.--white {
    padding: 0 32px;
    background-color: #FFF;
    color: #0062FF;
    transition: box-shadow 0.2s ease;
    border: solid 1px #D7D7D7; }
    .c-btn.--white:hover {
      border-color: #0062FF;
      background-color: #0062FF;
      color: white; }
  .c-btn.--shadow {
    border: none;
    box-shadow: 0px 1.956px 2.608px 0px rgba(0, 0, 0, 0.47); }
    .c-btn.--shadow:hover {
      background-color: white;
      box-shadow: 0px 1.956px 7px 0px rgba(0, 0, 0, 0.47);
      color: #004ecc; }
  .c-btn.--light, .c-btn.c-footer__heading {
    font-weight: 400; }

.c-blog:hover {
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.15); }

.c-link {
  color: #0062FF;
  display: inline-flex;
  gap: 4px;
  align-items: center;
  cursor: pointer; }
  .c-link:hover {
    color: #0045b3; }
  .c-link.--white {
    color: #FFF; }
    .c-link.--white:hover {
      color: #FFF; }
  .c-link.--light, .c-link.c-footer__heading {
    color: #888888; }
    .c-link.--light:hover, .c-link.c-footer__heading:hover {
      color: #444444; }
  .c-link.--body {
    color: #444444; }
    .c-link.--body:hover {
      color: #0062FF; }

/*------------------------------------------------------------------------------
Input
-- Consistently present form elements
-- Input, Select, and Textarea
-------------------------------------------------------------------------------- */
.c-input {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative; }
  .c-input::after {
    font-family: "Font Awesome 6 Pro";
    font-weight: 400;
    color: #444444;
    position: absolute;
    right: 12px;
    bottom: 13px;
    font-size: 1.4rem;
    pointer-events: none; }
  .c-input input, .c-input select, .c-input textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    vertical-align: top;
    border: solid 1px #D7D7D7;
    display: inline-block;
    color: #888888;
    outline: 0;
    height: 40px;
    width: 100%;
    padding: 6px 12px;
    border-radius: 2px;
    font-size: 1.4rem;
    line-height: 1.42857143;
    background-color: #fff; }
    @media (max-width: 767px) {
      .c-input input, .c-input select, .c-input textarea {
        font-size: 1.6rem; } }
    .c-input input::placeholder, .c-input select::placeholder, .c-input textarea::placeholder {
      color: #9E9E9E;
      opacity: 1; }
    .c-input input[disabled], .c-input select[disabled], .c-input textarea[disabled] {
      background-color: #F9F9F9;
      border: solid 1px #D7D7D7;
      opacity: 1; }
  .c-input textarea {
    height: inherit !important; }
  .c-input.--horiz {
    flex-direction: row;
    align-items: center;
    gap: 6px; }
    .c-input.--horiz .c-input__label {
      margin-top: 1px; }
  .c-input select {
    padding: 6px 24px 6px 12px; }
  .c-input.--select::after {
    content: "\f078";
    /* fa-chevron-down */ }
  .c-input.--select.--small input {
    height: 29px; }
  .c-input.--select.--small select {
    padding: 3px 24px 3px 12px; }
  .c-input.--tag input {
    padding: 6px 12px 6px 32px; }
  .c-input.--tag::after {
    content: "\f02b";
    /* fa-chevron-down */
    color: #0062FF;
    right: inherit;
    bottom: 11px;
    left: 12px;
    font-size: 1.6rem; }
  .c-input.--search input {
    padding: 6px 12px 6px 38px; }
  .c-input.--search::after {
    content: "\f002";
    /* fa-chevron-down */
    color: #000;
    right: inherit;
    bottom: 11px;
    left: 12px;
    font-size: 1.6rem; }
  .c-input.--has-btn {
    position: relative; }
    .c-input.--has-btn .c-btn {
      position: absolute;
      bottom: 0;
      right: 0; }
  .c-input__label {
    font-size: 1.4em; }

.c-popup {
  position: absolute;
  inset: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  padding: 32px;
  display: flex; }
  .c-popup__wrap {
    position: relative;
    z-index: 100;
    width: 100%;
    min-height: 174px;
    background-color: #fff;
    border-radius: 4px;
    padding: 30px;
    display: flex;
    flex-direction: column; }
  .c-popup.--sm .c-popup__wrap {
    max-width: 465px; }
  .c-popup.--md .c-popup__wrap, .c-popup.c-popup__head .c-popup__wrap, .c-popup.c-footer__heading .c-popup__wrap {
    max-width: 665px; }
  .c-popup.--lg .c-popup__wrap {
    max-width: 865px; }
  .c-popup__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.4em;
    color: #9E9E9E; }
    .c-popup__close:hover {
      color: #444444; }
  .c-popup__close-bg {
    position: absolute;
    inset: 0; }
  .c-popup__contents {
    display: flex;
    flex-direction: column;
    gap: 16px; }
  .c-popup__head {
    text-align: center;
    margin-bottom: 24px; }
  .c-popup__buttons {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-top: 16px; }
  .c-popup.--sm .c-popup__head {
    margin-bottom: 12px; }
  .c-popup.--sm .c-popup__buttons {
    margin-top: 4px; }

.c-popup-menu {
  position: absolute;
  background-color: #FFF;
  border: solid 1px #D7D7D7;
  border-radius: 4px;
  padding: 12px 0;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
  cursor: default;
  z-index: 200; }
  .c-popup-menu__item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 400;
    padding: 8px 20px;
    cursor: pointer; }
    .c-popup-menu__item i {
      min-width: 14px;
      display: flex;
      justify-content: center;
      align-items: center; }
    .c-popup-menu__item:hover {
      background-color: #F9F9F9; }
  .c-popup-menu.--type-ahead {
    padding: 0; }
    .c-popup-menu.--type-ahead i {
      color: #0062FF; }
  .c-popup-menu .c-type-ahead__results {
    position: relative;
    top: 0px;
    box-shadow: inherit; }
  .c-popup-menu .c-type-ahead__results {
    border: inherit; }
  .c-popup-menu .c-type-ahead input {
    border: none;
    border-radius: 3px 3px 0 0;
    border-bottom: solid 1px #D7D7D7; }

.js-popup-menu-trigger {
  overflow: inherit !important;
  user-select: none; }

.c-hero {
  display: flex;
  min-height: 370px;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px; }
  @media (max-width: 435px) {
    .c-hero {
      min-height: 256px; } }
  @media (min-width: 992px) {
    .c-hero {
      min-height: 485px; } }
  @media (min-width: 992px) {
    .c-hero {
      gap: 16px; } }
  .c-hero.--short {
    min-height: 256px; }
    @media (min-width: 992px) {
      .c-hero.--short {
        min-height: 356px; } }
  .c-hero.--purple {
    background-image: image-set(url("/img/backgrounds/header-bg-purple.jpg") 1x, url("/img/backgrounds/header-bg-purple@2x.jpg") 2x); }
  .c-hero.--red {
    background-image: image-set(url("/img/backgrounds/header-bg-red.jpg") 1x, url("/img/backgrounds/header-bg-red@2x.jpg") 2x); }
  .c-hero.--blue {
    background-image: image-set(url("/img/backgrounds/header-bg-blue.jpg") 1x, url("/img/backgrounds/header-bg-blue@2x.jpg") 2x); }
  .c-hero.--home {
    background-image: image-set(url("/img/backgrounds/header-bg-home.jpg") 1x, url("/img/backgrounds/header-bg-home@2x.jpg") 2x);
    padding: 104px 16px 32px 16px; }
    @media (max-width: 991px) {
      .c-hero.--home img {
        max-width: 300px; } }
  .c-hero__head {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.47);
    font-family: Barlow;
    font-size: 72px;
    font-weight: 700;
    letter-spacing: .095em;
    text-transform: uppercase; }
    @media (max-width: 435px) {
      .c-hero__head {
        font-size: 50px; } }
    @media (min-width: 768px) {
      .c-hero__head {
        font-size: 65px; } }
    @media (min-width: 992px) {
      .c-hero__head {
        font-size: 76px; } }
    @media (min-width: 1224px) {
      .c-hero__head {
        font-size: 92px; } }
    @media (max-width: 991px) {
      .c-hero__head.--csra {
        font-size: 60px; } }
    @media (max-width: 575px) {
      .c-hero__head.--csra {
        font-size: 42px; } }
    @media (max-width: 435px) {
      .c-hero__head.--csra {
        font-size: 36px; } }
  .c-hero__subhead {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    font-family: Barlow;
    font-size: 24px;
    font-weight: 275;
    letter-spacing: .25em;
    text-transform: uppercase; }
    @media (min-width: 576px) {
      .c-hero__subhead {
        font-size: 24px; } }
    @media (min-width: 768px) {
      .c-hero__subhead {
        font-size: 32px; } }
    @media (min-width: 992px) {
      .c-hero__subhead {
        font-size: 41px; } }
    @media (min-width: 1224px) {
      .c-hero__subhead {
        font-size: 50px; } }

.c-main-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 62px;
  padding-top: 32px;
  padding-bottom: 32px; }
  @media (min-width: 576px) {
    .c-main-content {
      gap: 92px;
      padding-top: 60px;
      padding-bottom: 60px; } }
  @media (min-width: 992px) {
    .c-main-content {
      gap: 136px;
      padding-top: 80px;
      padding-bottom: 80px; } }
  .c-main-content.--no-gap {
    gap: 0; }

.c-icon-group {
  text-align: center; }
  .c-icon-group i {
    font-size: 4em;
    color: #0062FF;
    height: 40px;
    margin-bottom: 12px; }

.c-icon-highlight {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }
  .c-icon-highlight__icon {
    filter: drop-shadow(0px 28px 48px rgba(0, 0, 0, 0.16)) drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.3)); }
  .c-icon-highlight__wrap {
    display: flex; }
  .c-icon-highlight.--server-bg {
    background-image: image-set(url("/img/backgrounds/bg-server.png") 1x, url("/img/backgrounds/bg-server@2x.png") 2x);
    background-size: cover;
    background-color: #0D63EE; }

.c-point {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; }
  .c-point__icon {
    font-size: 3.2em;
    color: #0062FF;
    margin-bottom: 12px; }
  .c-point__wrap {
    padding: 24px 16px 20px 16px;
    flex-grow: 1; }

.c-illustration {
  display: flex;
  justify-content: center; }
  @media (max-width: 575px) {
    .c-illustration {
      padding-bottom: 8px; } }
  @media (min-width: 992px) {
    .c-illustration.--left img {
      padding-right: 16px; } }
  @media (min-width: 992px) {
    .c-illustration.--right img {
      padding-left: 16px; } }
  @media (max-width: 767px) {
    .c-illustration__section {
      row-gap: 24px; } }
  @media (min-width: 768px) {
    .c-illustration__section {
      row-gap: 32px; } }
  @media (min-width: 992px) {
    .c-illustration__section {
      row-gap: 40px; } }

.c-cta {
  display: flex;
  padding: 16px 24px;
  border-radius: 8px;
  align-items: center;
  gap: 16px;
  margin-top: -20px;
  margin-bottom: -20px; }
  @media (min-width: 992px) {
    .c-cta {
      margin-top: -40px;
      margin-bottom: -40px; } }
  .c-cta__logo {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: #0062FF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0; }
    @media (max-width: 575px) {
      .c-cta__logo {
        display: none; } }
    .c-cta__logo img {
      filter: drop-shadow(0px 0.6px 0.6px rgba(0, 0, 0, 0.25));
      width: 12px; }

@media (min-width: 992px) {
  .c-radial-layout {
    background-size: 68%;
    background-position: 50% 10%;
    background-repeat: no-repeat; } }

@media (min-width: 1224px) {
  .c-radial-layout {
    background-size: 68%;
    background-position: 48% 8%; } }

@media (min-width: 992px) {
  .c-radial-layout.--app-windows {
    background-image: url("/img/illustrations/radial-app-windows.svg"); } }

@media (min-width: 992px) {
  .c-radial-layout.--compliance {
    background-image: url("/img/illustrations/radial-compliance.svg"); } }

@media (min-width: 1224px) {
  .c-radial-layout .c-point {
    padding: 0 30px; } }

.c-csra_section {
  background-image: image-set(url("/img/backgrounds/bg-blue-blur.jpg") 1x, url("/img/backgrounds/bg-blue-blur@2x.jpg") 2x);
  background-size: cover;
  min-height: 633px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 56px 16px; }
  .c-csra_section__heading, .c-csra_section__subhead {
    color: #FFF;
    text-align: center;
    font-family: Barlow;
    text-transform: uppercase; }
  .c-csra_section__heading {
    text-shadow: 0px 1.956px 2.608px rgba(0, 0, 0, 0.47);
    font-size: 60px;
    font-weight: 700;
    letter-spacing: 5.5px; }
    @media (max-width: 575px) {
      .c-csra_section__heading {
        font-size: 32px; } }
  .c-csra_section__subhead {
    text-shadow: 0px 2.608px 2.608px rgba(0, 0, 0, 0.4);
    font-size: 33px;
    font-weight: 275;
    letter-spacing: 8px; }
    @media (max-width: 575px) {
      .c-csra_section__subhead {
        font-size: 18px; } }
  .c-csra_section__body {
    color: #FFF;
    max-width: 712px;
    text-align: center; }

.c-footer {
  border-top: solid 1px #E7E7E7;
  background-color: #FCFBFB;
  padding: 32px 0 16px 0; }
  .c-footer__wrap {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 767px) {
      .c-footer__wrap {
        flex-direction: column;
        gap: 24px; } }
  .c-footer__icon {
    color: #9E9E9E;
    font-size: 10px;
    width: 16px;
    padding-right: 6px;
    text-align: center; }
  .c-footer__heading {
    margin-bottom: 8px; }
  @media (min-width: 768px) {
    .c-footer__summary {
      width: 264px; } }
  @media (min-width: 992px) {
    .c-footer__summary {
      width: 400px; } }
  @media (min-width: 1224px) {
    .c-footer__summary {
      width: 480px; } }
  .c-footer__bottom {
    text-align: center;
    border-top: solid 1px #E7E7E7;
    margin-top: 32px;
    padding-top: 16px; }

.c-icon-circle {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px; }
  @media (max-width: 575px) {
    .c-icon-circle {
      flex-direction: row;
      justify-content: center;
      text-align: left; } }
  @media (min-width: 576px) and (max-width: 767px) {
    .c-icon-circle {
      min-width: 145px; } }
  .c-icon-circle i {
    color: #0062FF;
    font-size: 2.4em;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    border: solid 1px #D7D7D7;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 575px) {
      .c-icon-circle i {
        height: 36px;
        width: 36px;
        border-radius: 18px;
        font-size: 1.6em;
        flex-shrink: 0; } }
    @media (min-width: 992px) {
      .c-icon-circle i {
        font-size: 3.2em;
        width: 90px;
        height: 90px;
        border-radius: 45px; } }
    .c-icon-circle i:hover {
      background-color: #0062FF;
      color: #FFF;
      border-color: #0062FF; }
  .c-icon-circle__wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    row-gap: 24px; }
    @media (max-width: 575px) {
      .c-icon-circle__wrap {
        row-gap: 16px; } }

.c-anchor {
  scroll-margin-top: 90px; }

.c-list {
  list-style-type: disc;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 12px; }
  .c-list.--ordered {
    list-style-type: decimal; }
  .c-list.--flush {
    padding-left: 16px; }
  .c-list.--compact {
    gap: 6px; }
  .c-list li {
    display: list-item; }

.c-blog__entry {
  padding: 32px; }

.c-blog__image {
  border-radius: 4px;
  margin-bottom: 32px;
  border: solid 1px #D7D7D7; }

.c-blog__wrap {
  margin-top: 12px; }
  .c-blog__wrap p {
    padding-bottom: 24px; }
  .c-blog__wrap ul {
    padding-bottom: 24px; }
  .c-blog__wrap ol {
    padding-bottom: 24px; }
  .c-blog__wrap h2 + ul {
    margin-top: 12px; }
  .c-blog__wrap .t-head + .c-blog__section-head, .c-blog__wrap .t-section__head + .c-blog__section-head, .c-blog__wrap .c-input__label + .c-blog__section-head, .c-blog__wrap .c-popup__head + .c-blog__section-head, .c-blog__wrap .c-icon-group__head + .c-blog__section-head, .c-blog__wrap .c-point__head + .c-blog__section-head, .c-blog__wrap .c-footer__heading + .c-blog__section-head, .c-blog__wrap .c-blog__section-head + .c-blog__section-head {
    margin-top: 24px; }
  .c-blog__wrap h2 + img {
    margin-top: 24px; }
  .c-blog__wrap img {
    padding-bottom: 24px; }

.c-logo-feature {
  display: flex;
  justify-content: center;
  border-top: solid 1px #D7D7D7;
  padding: 40px 0;
  gap: 40px; }
  .c-logo-feature img {
    height: 95px; }

.c-badge {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #D7D7D7;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  flex-grow: 0;
  color: #000; }
  .c-badge .fa-shield-check {
    font-size: 22px; }
  .c-badge__group {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center; }
