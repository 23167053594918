.row.--gap-16 {
    margin-right: -8px;
    margin-left: -8px;
}

.row.--gap-16>[class^="col"] {
    padding-right: 8px;
    padding-left: 8px;
}

@include xs-max {
    .container {
        padding-right: 16px;
        padding-left: 16px;
    }
}