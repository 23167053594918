.c-radial-layout {
    @include lg {
        background-size: 68%;
        background-position: 50% 10%;
        background-repeat: no-repeat;
    }
    @include xl {
        background-size: 68%;
        background-position: 48% 8%;
    }

    &.--app-windows {
        @include lg {
        	background-image: url('/img/illustrations/radial-app-windows.svg');
        }
    }

    &.--compliance {
        @include lg {
        	background-image: url('/img/illustrations/radial-compliance.svg');
        }
    }
}

// Point – Formatting / Position
.c-radial-layout .c-point {
    @include xl {padding: 0 30px;}
}
