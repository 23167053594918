.c-popup-menu {
	position: absolute;
	// top: defined by js
	// left: defined by js
	background-color: $white;
	border: $border;
	border-radius: 4px;
	padding: 12px 0;
	box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
	cursor: default;
	z-index: 200;

	&__item {
		display: flex;
		align-items: center;
		gap: 10px;
		@extend .t-body;
		font-weight: 400;
		padding: 8px 20px;
		cursor: pointer;

		i {
			min-width: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&:hover {
			background-color: $light-bg;
		}
	}

	&.--type-ahead {
		padding: 0;

		i {color: $blue;}
	}

	.c-type-ahead {

		&__results {
			position: relative; // Popup no longer floating – takes up space inside popover
			top: 0px;
			box-shadow: inherit;
		}

		&__results {
			border: inherit;
		}

		input {	
			border: none;
			border-radius: 3px 3px 0 0;
			border-bottom: $border;
		}
	}
}

.js-popup-menu-trigger {
	overflow: inherit!important;
	user-select: none;
}