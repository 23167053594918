.c-logo-feature {
    display: flex;
    justify-content: center;
    border-top: $border;
    padding: 40px 0;
    gap: 40px;

    img {
        height: 95px;
    }
}

