.c-icon-circle {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
	@include xs-max {
		flex-direction: row;
		justify-content: center;
		text-align: left;
	}

	@include sm-only {
		min-width: 145px;
	}

	i {
		color: $blue;
		font-size: 2.4em;
		width: 70px;
		height: 70px;
		border-radius: 35px;
		border: $border;
		display: flex;
		justify-content: center;
		align-items: center;

		@include xs-max {
			height: 36px;
			width: 36px;
			border-radius: 18px;
			font-size: 1.6em;
			flex-shrink: 0;
		}

		@include lg {
			font-size: 3.2em;
			width: 90px;
			height: 90px;
			border-radius: 45px;
		}

		&:hover {
			background-color: $blue;
			color: $white;
			border-color: $blue;
		}
	}

	&__wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
		row-gap: 24px;
		@include xs-max {
			row-gap: 16px;
		}
	}
}




