/*******************************************************************************************
* Typography
********************************************************************************************/

.t-head {
	font-weight: 700;
	line-height: 120%;
	font-size: 2em;
	color: $black;
	padding-bottom: .25em;

	&.--sm {
		font-size: 1.6em;
		padding-bottom: .2em;
	}

	&.--md {
		font-size: 1.7em;
		padding-bottom: .2em;
	}

	&.--lg {font-size: 2.4em;}

	&.--lh-md {
		padding-bottom: .05em;
		line-height: 145%;
	}

	&.--lh-lg {line-height: 160%;}

	&.--light {
		font-weight: 400;
	}

	&.--with-icon,
	&.--with-md-icon {

		i {
			padding-right: 8px;
			color: $blue;
			font-size: 2.4rem;
			@include xs-max {
				font-size: 2rem;
				top: 0!important; // Overides -nudge- in xs only
			}
		}
	}

	&.--with-md-icon {
		i {
			font-size: 2.1rem;
			padding-right: 4px;
		}
	}
}

.t-body {
	font-size: 1.6rem;
	line-height: 120%;
	&.--xs {font-size: 1.2em;}
	&.--sm {font-size: 1.4em;}
	&.--md {font-size: 1.8em;}
	&.--lh-sm {line-height: 150%;}
	&.--lh-md {line-height: 170%;}
	&.--lh-lg {line-height: 190%;}
}

/*------------------------------------------------------------------------------
Common Use
-------------------------------------------------------------------------------- */
.t-section__head {
	@extend .t-head;
	@extend .t-head.--with-icon;
	@extend .t-head.--lh-md;
}

.t-section__body {
	@extend .t-body;
	@extend .t-body.--lh-lg;
}