.c-blog {

	&__entry {
		@extend .o-card;
		@extend .o-card.--hover-shadow;
		padding: 32px;
	}

	&__image {
		border-radius: 4px;
		margin-bottom: 32px;
		border: $border;
	}

	&__wrap {
		margin-top: 12px;
		p {padding-bottom: 24px;}
		ul {padding-bottom: 24px;}
		ol {padding-bottom: 24px;}
		h2 + ul {margin-top: 12px;} // ul that is right after an h2
		.t-head + .c-blog__section-head {margin-top: 24px;} // ul that is right after an h2
		h2 + img {margin-top: 24px;}
		img {padding-bottom: 24px;}
	}

	&__paragraph {
		@extend .t-body;
		@extend .t-body.--lh-md;
	}

	&__section-head {
		@extend .t-head;
		@extend .t-head.--md;		
	}
}


