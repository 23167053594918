.c-btn {
	display: inline-flex;
	height: 42px;
	align-items: center;
	gap: 6px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 700;
	padding: 0 16px;
	border-radius: 4px;
	cursor: pointer;
	font-size: 14px;
	background-color: $blue;
	color: $white;

	&:hover {
		background-color: darken($blue, 7%);
	}

	&.--white {
		padding: 0 32px;
		background-color: $white;
		color: $blue;
		transition: box-shadow 0.2s ease;
		border: $border;
		
		&:hover {
			border-color: $blue;
			background-color: $blue;
			color: white;
		}
	}

	&.--shadow {
		border: none;
		box-shadow: 0px 1.956px 2.608px 0px rgba(0, 0, 0, 0.47);

		&:hover {
			background-color: white;
			box-shadow: 0px 1.956px 7px 0px rgba(0, 0, 0, 0.47);
			color: darken($blue, 10%);
		}
	}

	&.--light {
		font-weight: 400;
	}
}

.c-blog {
	&:hover {
		box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.15);
	}
}


