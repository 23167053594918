.c-popup {
	position: absolute;
	inset: 0;
	z-index: 1000;
	background-color: rgba(0, 0, 0, .5);
	align-items: center;
	justify-content: center;
	padding: 32px;
	display: flex;

	&__wrap {
		position: relative;
		z-index: 100;
		width: 100%;
		min-height: 174px;
		background-color: #fff;
		border-radius: 4px;
		padding: 30px;
		display: flex;
		flex-direction: column;
	}

	&.--sm .c-popup__wrap {max-width: 465px;}
	&.--md .c-popup__wrap {max-width: 665px;}
	&.--lg .c-popup__wrap {max-width: 865px;}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		font-size: 1.4em;
		color: $light-text;

		&:hover {
			color: $dark-text;
		}
	}

	&__close-bg {
		position: absolute;
		inset: 0;
	}

	&__contents {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	&__head {
		@extend .t-head;
		@extend .--md;
		text-align: center;
		margin-bottom: 24px;
	}

	&__buttons {
		display: flex;
		gap: 8px;
		justify-content: center;
		margin-top: 16px;
	}

	&.--sm {
		.c-popup__head {margin-bottom: 12px;}
		.c-popup__buttons {margin-top: 4px;}
	}
}