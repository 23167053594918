.c-footer {
	border-top: $light-border;
	background-color: $extra-light-bg;
	padding: 32px 0 16px 0;

	&__wrap {
		display: flex;
		justify-content: space-between;

		@include sm-max {
			flex-direction: column;
			gap: 24px;
		}
	}

	&__icon {
		color: $light-text;
		font-size: 10px;
		width: 16px;
		padding-right: 6px;
		text-align: center;
	}

	&__heading {
		@extend .t-head;
		@extend .--md;
		@extend .--light;
		margin-bottom: 8px;
	}

	&__summary {
		@include md {width: 264px;}
		@include lg {width: 400px;}
		@include xl {width: 480px;}
	}

	&__bottom {
		text-align: center;
		border-top: $light-border;
		margin-top: 32px;
		padding-top: 16px;
	}
}

