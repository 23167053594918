/*------------------------------------------------------------------------------
Card
-- White, rounded box with a border
-------------------------------------------------------------------------------- */
.o-card {
    @include o-card;

     &.--blue-bg-hover:hover {
        background-color: $blue;
        border-color: $blue;

        .c-point__icon, .c-point__head {
            color: $white;
        }
    }

    &.--hover-shadow:hover {
        box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.15);
    }
}
