.c-nav {
	min-height: $nav-height;
	border-bottom: $border;
	background-color: $white;
	box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.25);
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 1;
	display: flex;
	align-items: center;

	@include lg-max {
		align-items: inherit;
	}

	&__wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		@include lg-max {
			padding-top: 12px;
			padding-bottom: 12px;
		}
	}

	&__logo {
		width: 90px;
		height: 31px;
		display: block;
	}

	&__menu {
		font-size: 1.6em;
		cursor: pointer;
		padding: 8px 0 8px 8px;
		@include xl {
			display: none;
		}
	}

	&__links {
		display: flex;
		font-size: 1.4em;
		gap: 32px;
		color: $medium-text;

		a:hover {
			color: $black;
		}

		@include lg-max {
			display: none;
			width: 100%;
			flex-direction: column;
			text-align: center;
			margin-top: 40px;
			padding-bottom: 16px;
		}

		@include xl {
			display: flex!important; // Force nav links to display in larger breakpoints (even if hidden in mobile)
		}

		.-active {
			color: $black;
		}
	}
}