.c-badge {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid $border-gray;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    flex-grow: 0;
    color: $black;

    .fa-shield-check {
        font-size: 22px;
    }

    &__group {
        display: flex;
        gap: 12px;
        justify-content: center;
        align-items: center;
    }
}

