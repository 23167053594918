.c-point {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	&__icon {
		font-size: 3.2em;
		color: $blue;
		margin-bottom: 12px;
	}

	&__wrap {
		padding: 24px 16px 20px 16px;
		flex-grow: 1;
	}

	&__head {
		@extend .t-head;
		@extend .t-head.--sm;
		@extend .t-head.--lh-md;
	}

	&__body {
		@extend .t-body;
		@extend .t-body.--sm;
		@extend .t-body.--lh-md;
	}
}