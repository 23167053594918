/*******************************************************************************************
* Base Styles
-- Global styles that set a "base" for the rest of the document
********************************************************************************************/

/*------------------------------------------------------------------------------
Global Adjustments
-------------------------------------------------------------------------------- */
// Easy EMs
html {
	font-size: 62.5%; /* This makes 1em = 10px */
    text-rendering: geometricPrecision;
	-webkit-font-smoothing: antialiased;
}

// Global Type Adjustments
body, input, select, textarea {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    color: $dark-text;
	-webkit-text-size-adjust: none; // Prevents scaling of text when a phone is rotated.
}

body {
    padding-top: $nav-height;// Accomodates for nav
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

// Link Style Rest
a {
    text-decoration: none;
    color: inherit;
}

// Bold
strong {
    font-weight: 700;
    font-style: normal;
    color: $black;
}

// Italics
em {
    font-style: italic;
}

// Fluid images
img, object, embed, video {
	max-width: 100%;
}

// Remove Button Border
button {border: 0;}

