.c-icon-group {
	text-align: center;

	i {
		font-size: 4em;
		color: $blue;
		height: 40px;
		margin-bottom: 12px;
	}

	&__head {
		@extend .t-head;
		@extend .t-head.--sm;
	}

	&__body {
		@extend .t-body;
		@extend .t-body.--sm;
		@extend .t-body.--lh-sm;
	}
}