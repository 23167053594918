.c-hero{
	display: flex;
	min-height: 370px;
	@include below-435 {min-height: 256px;}
	@include lg {min-height: 485px;}
	background-size: cover;
	background-position: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	@include lg {gap: 16px;}

	&.--short {
		min-height: 256px;
		@include lg {min-height: 356px;}
	}

	&.--purple {
		background-image: image-set(
			url('/img/backgrounds/header-bg-purple.jpg') 1x,
			url('/img/backgrounds/header-bg-purple@2x.jpg') 2x
		);
	}

	&.--red {
		background-image: image-set(
			url('/img/backgrounds/header-bg-red.jpg') 1x,
			url('/img/backgrounds/header-bg-red@2x.jpg') 2x
		);
	}

	&.--blue {
		background-image: image-set(
			url('/img/backgrounds/header-bg-blue.jpg') 1x,
			url('/img/backgrounds/header-bg-blue@2x.jpg') 2x
		);
	}

	&.--home {
		background-image: image-set(
			url('/img/backgrounds/header-bg-home.jpg') 1x,
			url('/img/backgrounds/header-bg-home@2x.jpg') 2x
		);


		padding: 104px 16px 32px 16px;

		img {
			@include md-max {max-width: 300px;}
		}
	}

	&__head {
		color: $white;
		text-align: center;
		text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.47);
		font-family: Barlow;
		font-size: 72px;
		font-weight: 700;
		letter-spacing: .095em; //8.5px
		text-transform: uppercase;
		// @include sm {font-size: 50px;}
		@include below-435 {font-size: 50px;}
		@include md {font-size: 65px;}
		@include lg {font-size: 76px;}
		@include xl {font-size: 92px;}

		&.--csra {
			@include md-max {font-size: 60px}
			@include xs-max {font-size: 42px;}
			@include below-435 {font-size: 36px;}
		}
	}

	&__subhead {
		color: $white;
		text-align: center;
		text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.40);
		font-family: Barlow;
		font-size: 24px;
		font-weight: 275;
		letter-spacing: .25em; //12.25px
		text-transform: uppercase;
		@include sm {font-size: 24px;}
		@include md {font-size: 32px;}
		@include lg {font-size: 41px;}
		@include xl {font-size: 50px;}
	}
}

