.c-cta {
	display: flex;
	padding: 16px 24px;
	border-radius: 8px;
	align-items: center;
	gap: 16px;
	margin-top: -20px;
	margin-bottom: -20px;

	@include lg {
		margin-top: -40px;
		margin-bottom: -40px;
	}

	&__logo {
		width: 33px;
		height: 33px;
		border-radius: 50%;
		background-color: $blue;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		@include xs-max {
			display: none;
		}
		
		img {
			filter: drop-shadow(0px 0.6px 0.6px rgba(0, 0, 0, 0.25));
			width: 12px;
		}
	}	
}